<template>
  <div class="accordion">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    calculateHeight: {
      type: Boolean,
      default: () => true
    }
  },
  mounted() {
    let links = this.$el.querySelectorAll(".accordion-link");

        for (let i = 0; links.length > i; i++) {
      links[i].addEventListener("click", e => {
        e.preventDefault();
        e.currentTarget.classList.toggle("active");

        let panel = e.currentTarget.nextElementSibling;
        let maxHeight = this.calculateHeight ? panel.scrollHeight : 1500;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = maxHeight + "px";
        }
      });
        }
  }
};
</script>

