<template>
    <div class="component component--quotations-export-overlay content">

        <h2>
            Offertes Exporteren
        </h2>

        <p>
            Kies een begin- en eind-datum voor de offertes die geëxporteerd mogen worden.
        </p>

        <form>

            <div class="row">

                <div class="w100">

                    <div class="input-head">
                        Begin-datum
                    </div>

                    <datetime type="date"
                              v-model="inputStartDate"
                              value-zone="local"
                              class="input-field"
                              placeholder="Kies een datum" />

                </div>

            </div>

            <div class="row">

                <div class="w100">

                    <div class="input-head">
                        Eind-datum
                    </div>

                    <datetime type="date"
                              v-model="inputEndDate"
                              value-zone="local"
                              class="input-field"
                              placeholder="Kies een datum" />

                </div>

            </div>

            <button class="btn dark"
                    :disabled="!inputStartDate || !inputEndDate"
                    @click.prevent="exportClicked">
                Exporteer
            </button>

        </form>

    </div>
</template>

<script>

    import { Datetime } from "vue-datetime";
    import { mapActions } from "vuex";
    import "vue-datetime/dist/vue-datetime.css";

    export default {

        name: 'quotations-export-overlay',

        components: {
            Datetime
        },

        props: {
            content: Object
        },

        data() {
            return {
                inputStartDate: null,
                inputEndDate: null,
            }
        },

        computed: {

            startDate() {

                return new Date(this.inputStartDate);
            },

            endDate() {

                let endDate = new Date(this.inputEndDate);

                endDate.setHours(23, 59, 59);

                return endDate;
            },
        },
        
        methods: {

            ...mapActions('quotations', [
                'getExportUrl',
            ]),

            ...mapActions('ui', [
               'closeOverlay',
            ]),

            exportClicked() {

                let dateRange = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                };

                this.getExportUrl(dateRange).then((url) => {

                    window.open(url, '_blank');
                });

                this.closeOverlay();
            },
        }
    };

</script>