import api from '~/src/api';
import { getField, updateField } from 'vuex-map-fields';
import axios from 'axios';
import Vue from 'vue';
import { clone } from "lodash";

const SET_ACTIVE_CLIENTS = 'setActiveClients';
const SET_MEASURES = 'setMeasures';
const SET_DIMENSION_DEFAULTS = 'setDimensionDefaults';
const SET_CLIENT_PLAN = 'setClientPlan';
const UPDATE_CLIENT_PLAN_MEASURES='updateClientPlanMeasures';
const UPDATE_FIELD = 'updateField';
const SAVE_MEASURES = 'saveMeasures';
const SAVE_DIMENSIONS = 'saveDimensions';
const SET_CLIENT = 'setClient';
const SAVE_PROPOSED_MEASURES = 'saveProposedMeasures';
const SET_ACTIONABLE_CLIENTS = 'setActionableClients';
const SET_CURRENT_PLAN_STATE = 'setCurrentPlanState';
const SET_ADVISORS = 'loadAdvisors';
const UPDATE_UPLOAD_FILE = 'updateUploadFile';
const LOAD_UPLOAD_FILE = 'loadUploadFile';
const UPDATE_UPLOAD_FILE_COMMENT = 'updateUploadFileComment';
const REMOVE_UPLOAD_FILE = 'removeUploadFile';
const UPLOAD_SAVED_FILES = 'uploadSavedFiles';
const CLEAR_FILE_CACHE = 'clearFileCache';
const UPDATE_CONSUMER_SITUATION = 'updateConsumerSituation';
const SET_CURRENT_SITUATION_USER_EDITABLE = 'setCurrentSituationUserEditable';
const SAVE_UPLOADFILESTORAGE = 'saveUploadFileStorage';
const SAVE_IMG_SAVED = 'SaveImgSaved';
const SAVE_IMG_DOCID = 'SaveImgDocId';
const SAVE_IMG_DIRTY = 'SaveImgDirty';

const consumerProperties = [
  'areaOfUse',
  'buildingType',
  'buildYear',
  'ambition',
  'reason',
  'monthlyDeposit',
  'energyEfficiency',
  'numberOfAdults',
  'stageOfLife',
  'numberOfChildren',
  'cvPlaceYear',
];

const state = {
  userId: null,
  clients: null,
  client: null,
  actionableClients: null,
  clientPlan: null,
  measures: [],
  proposedMeasures: {},
  advisePlanMeasures: null,
  advisePlanDimensions: null,
  currentPlanState: null,
  advisors: null,
  uploadFileStorage: {}
};

consumerProperties.forEach((p) => {
  state[p] = null;
});

const getters = {
  getField
};

const actions = {
  async loadAdvisorPlanFilesByUserId({ commit }, userId) {
    var data = await api.getAdvisorDocumentsByUserId(userId);
    var counter = {};
    data.forEach((serverImage) => {
      counter[serverImage.subject] = counter[serverImage.subject] + 1 || 0;
      commit(LOAD_UPLOAD_FILE,
        {
          groupName: serverImage.groupName,
          buildingPartName: serverImage.subject,
          index: counter[serverImage.subject],
          src: serverImage.url,
          comment: serverImage.description,
          documentId: serverImage.id
        });
    });
  },

  async uploadSavedFiles({ commit }, clientId) {
    commit(UPLOAD_SAVED_FILES, clientId);
  },

  async updateUploadFile({ commit }, data) {
    commit(UPDATE_UPLOAD_FILE, data);
  },

  async updateUploadFileComment({ commit }, data) {
    commit(UPDATE_UPLOAD_FILE_COMMENT, data);
  },

  async removeUploadFile({ commit }, data) {
    commit(REMOVE_UPLOAD_FILE, data);
  },

  async clearFileStorage({ commit }) {
    commit(CLEAR_FILE_CACHE);
  },

  async loadAdvisors({ commit }) {
    let advisors = await api.loadAdvisors();
    commit(SET_ADVISORS, advisors);
    return advisors;
  },
  async loadAdvisor(_context, id) {
    let advisor = await api.loadAdvisor(id);
    return advisor;
  },
  async loadActionableClients({ commit }) {
    let clients = await api.loadActionableClients();
    commit(SET_ACTIONABLE_CLIENTS, clients);
  },
  async loadActionableClientsForAdvisor({ commit }, advisorId) {
    let clients = await api.loadActionableClientsForAdvisor(advisorId);
    commit(SET_ACTIONABLE_CLIENTS, clients);
  },
  async setCurrentPlanState({ commit }, planState) {
    commit(SET_CURRENT_PLAN_STATE, planState);
  },
  async loadActiveClients({ commit }) {
    let clients = await api.loadActiveClients();
    commit(SET_ACTIVE_CLIENTS, clients);
  },
  async loadActiveClientsForAdvisor({ commit }, advisorId) {
    let clients = await api.loadActiveClientsForAdvisor(advisorId);
    commit(SET_ACTIVE_CLIENTS, clients);
  },
  async loadClient({ commit }, userId) {
    let client = await api.loadClient(userId);

    commit(SET_CLIENT, client);
  },
  async loadClientPlan({ commit }, userId) {
    let plan = await api.loadClientPlan(userId);

    commit(UPDATE_FIELD, { path: 'userId', value: userId });

    commit(SET_CLIENT_PLAN, plan);
  },
  async planChanged() {
    // the plan is changed, reload the dimension defaults for the new situation
    // dispatch('loadDimensionDefaults', {
    // 	areaOfUse: state.areaOfUse,
    // 	buildingType: state.buildingType
    // });
  },
  async loadMeasures({ commit }) {
    let measures = await api.loadAdvisorMeasures();
    commit(SET_MEASURES, measures);
    return measures;
  },

  async loadEapMeasures({ commit }) {
    let measures = await api.loadAdvisorEapMeasures();
    commit(SET_MEASURES, measures);
    return measures;
  },

  async loadVirtualMeasures({ commit }) {
    let measures = await api.loadAdvisorMeasures(true);
    commit(SET_MEASURES, measures);
    return measures;
  },

  async loadDimensionDefaults({ commit }, data) {
    let { areaOfUse, buildingType } = data;
    let defaults = await api.loadDimensionDefaults(buildingType, areaOfUse);
    commit(SET_DIMENSION_DEFAULTS, defaults);
  },
  async planAppointment({ dispatch }, data) {
    let { user, date } = data;
    await api.planAppointment(user, date);

    // reload our client list
    dispatch('loadActiveClients');
  },
  async deleteAppointment({ dispatch }, data) {
    let { user, date } = data;
    await api.deleteAppointment(user, date);

    // reload our client list
    dispatch('loadActiveClients');
  },
  async startAdvice({ commit }, userId) {
    let plan = await api.startAdvice(userId);
    commit(SET_CLIENT_PLAN, plan);
    commit(UPDATE_FIELD, { path: 'userId', value: userId });

    return plan;
  },

  async updateConsumerSituation({ commit, state }, { consumerSituation, dimensions}) {
    commit(UPDATE_CONSUMER_SITUATION, consumerSituation);

    if(consumerSituation.measures.constructor.name === 'Object') {
      let measureArray = [];
      Object.keys(consumerSituation.measures).forEach(buildingPartId => 
        Object.keys(consumerSituation.measures[buildingPartId]).forEach(groupName => {
          measureArray.push({key: buildingPartId, group: groupName, value: consumerSituation.measures[buildingPartId][groupName]})
        }));
      consumerSituation.measures = measureArray;
    }

    await api.updateAdvisePlan({
      consumerSituation,
      dimensions: dimensions,
      userId: state.userId
    });
  },

  async updateAdvisePlanSituation({ commit, state }, { measures, dimensions }) {
    commit(SAVE_MEASURES, measures);
    commit(SAVE_DIMENSIONS, dimensions);

    let consumerSituation = {};

    consumerProperties.forEach((p) => {
      consumerSituation[p] = state[p];
    });

    if(measures.constructor.name === 'Object') {
      let measureArray = [];
        Object.keys(measures).forEach(buildingPartId => {
            if (measures[buildingPartId])
                Object.keys(measures[buildingPartId]).forEach(groupName => {
                    let group = groupName === null || groupName === 'null' ? null : groupName;
                    let value = measures[buildingPartId][group];
                    measureArray.push({
                        key: buildingPartId,
                        group: group,
                        value: value === null || isNaN(value) ? null : parseInt(value)
                    })
                })
        });
      consumerSituation.measures = measureArray;
    }

    await api.updateAdvisePlan({
      consumerSituation,
      dimensions: state.advisePlanDimensions,
      userId: state.userId,
    });
  },

  async updateAdvisePlanMeasures({ commit }, { measures, term }) {
    commit(SAVE_PROPOSED_MEASURES, { measures, term });
  },
  async proposeMeasures({ state }) {
    return await api.proposeMeasures(state.userId);
  },
  async setCurrentSituationUserEditable({ commit }, clientId) {
    commit(SET_CURRENT_SITUATION_USER_EDITABLE, clientId);
    },
  async uploadSavedFilesAsync({ commit, state }, clientId) {
    let fileStorage = state.uploadFileStorage;
    Object.keys(fileStorage).forEach((groupName) => {
        Object.keys(fileStorage[groupName]).forEach(async (buildingPartName) => {
            for (var i = 0; i < fileStorage[groupName][buildingPartName].length; i++) {
                var img = fileStorage[groupName][buildingPartName][i];
                if (img.saved == false || img.saved == undefined) {
                    let formData = new FormData();
                    formData.append('files[0]', img.file);
                    let response = await axios.post(
                        '/api/v1/documents/uploadUserPlanDocumentByAdvisor',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                description: img.comment,
                                groupName: groupName,
                                subject: buildingPartName,
                                clientId: clientId
                            }
                        }
                    );

                    if (response.status >= 200 && response.status < 300) {
                        commit(SAVE_IMG_SAVED, { groupName, buildingPartName, i, saved: true });
                        commit(SAVE_IMG_DOCID, { groupName, buildingPartName, i, docId: response.data[0].document.id });
                    }
                } else if (img.saved == true && img.dirty == true) {
                    let response = await axios.post(
                        '/api/v1/documents/updateFileComment/' + img.documentId,
                        {},
                        {
                            headers: {
                                description: img.comment
                            }
                        }
                    );
                    if (response.status >= 200 && response.status < 300) {
                        commit(SAVE_IMG_DIRTY, { groupName, buildingPartName, i, dirty: false });
                    }
                }
            }
        });
    });
  }
};

const mutations = {
  updateField,
  [SET_CURRENT_PLAN_STATE](state, planState) {
    state.currentPlanState = planState;
  },

  [SET_ADVISORS](state, advisors) {
    state.advisors = advisors;
  },

  [SET_ACTIVE_CLIENTS](state, clients) {
    state.clients = clients;
  },

  [SET_MEASURES](state, measures) {
    state.measures = measures;
  },

  [SET_DIMENSION_DEFAULTS](state, defaults) {
    state.dimensionDefaults = defaults;
  },

  [SET_CLIENT_PLAN](state, plan) {
    state.clientPlan = plan;

    if(plan.consumerSituation != null)
      consumerProperties.forEach((val) => {
        updateField(state, { path: val, value: plan.consumerSituation[val] });
      });
  },
  [UPDATE_CLIENT_PLAN_MEASURES](state, measures) {
    if(state.clientPlan === null || state.clientPlan === undefined ||
      state.clientPlan.consumerSituation === null || state.clientPlan.consumerSituation === undefined)
      return;

    state.clientPlan.consumerSituation.measures = measures;
  },
  [SET_CLIENT](state, client) {
    state.client = client;
  },
  [SAVE_MEASURES](state, measures) {
    state.advisePlanMeasures = { ...state.advisePlanMeasures, ...measures };
  },
  [SAVE_DIMENSIONS](state, dimensions) {
    state.advisePlanDimensions = { ...state.advisePlanDimensions, ...dimensions };
  },
  [SAVE_PROPOSED_MEASURES](state, { term, measures }) {
    state.proposedMeasures[term] = measures;
  },
  [SET_ACTIONABLE_CLIENTS](state, clients) {
    state.actionableClients = clients;
  },

    [UPDATE_UPLOAD_FILE](state, { groupName, buildingPartName, index, src, file }) {
        if(!(groupName in state.uploadFileStorage))
            Vue.set(state.uploadFileStorage, groupName, {});

        if(!(buildingPartName in state.uploadFileStorage[groupName]))
            Vue.set(state.uploadFileStorage[groupName], buildingPartName, []);

        if (index === null) {
            index = state.uploadFileStorage[groupName][buildingPartName].length;
        }

        if (state.uploadFileStorage[groupName][buildingPartName][index] == undefined) {
            Vue.set(state.uploadFileStorage[groupName][buildingPartName], index, { src: src, comment: "", file: file, saved: false, documentId: null, dirty: false });
        }
    },

    [UPDATE_UPLOAD_FILE_COMMENT](state, { groupName, buildingPartName, index, comment }) {
        if(!(groupName in state.uploadFileStorage))
          state.uploadFileStorage[groupName] = [];

        if(!(buildingPartName in state.uploadFileStorage[groupName]))
          state.uploadFileStorage[groupName][buildingPartName] = [];
        
        if (index < state.uploadFileStorage[groupName][buildingPartName].length) {
            state.uploadFileStorage[groupName][buildingPartName][index].comment = comment;
            state.uploadFileStorage[groupName][buildingPartName][index].dirty = true;
        } else {
            window.console.error("Could not update upload file storage of building part " +
            buildingPartName +
            ", index was out of bounds of the array.");
        }
    },

    [LOAD_UPLOAD_FILE](state, { groupName, buildingPartName, index, src, comment, documentId }) {
        if(!(groupName in state.uploadFileStorage))
            Vue.set(state.uploadFileStorage, groupName, {});

        if(!(buildingPartName in state.uploadFileStorage[groupName]))
            Vue.set(state.uploadFileStorage[groupName], buildingPartName, []);

        if (index === null) {
            index = state.uploadFileStorage[groupName][buildingPartName].length;
        }

        if (!(index in state.uploadFileStorage[groupName][buildingPartName])) {
            Vue.set(state.uploadFileStorage[groupName][buildingPartName], index, { src: src, comment: comment, file: null, saved: true, documentId: documentId, dirty: false });
        }
    },

    [REMOVE_UPLOAD_FILE](state, { groupName, buildingPartName, index }) {
        if (
            !(groupName in state.uploadFileStorage)
            || !(buildingPartName in state.uploadFileStorage[groupName])
            || !(index in state.uploadFileStorage[groupName][buildingPartName])
        ) {
            return;
        }

        if (state.uploadFileStorage[groupName][buildingPartName][index].saved === true) {
            axios.get('/api/v1/documents/' +
            state.uploadFileStorage[groupName][buildingPartName][index].documentId +
            '/deleteDocument');
        }

        state.uploadFileStorage[groupName][buildingPartName].splice(index, 1);
    },

    [CLEAR_FILE_CACHE](state) {
      state.uploadFileStorage = {};
    },

    [SAVE_UPLOADFILESTORAGE](state, storage) {
        state.uploadFileStorage = storage;
    },

    [SAVE_IMG_SAVED](state, { groupName, buildingPartName, i, saved }) {
        if (
            !(groupName in state.uploadFileStorage)
            || !(buildingPartName in state.uploadFileStorage[groupName])
            || !(i in state.uploadFileStorage[groupName][buildingPartName])
        ) {
            return;
        }

        state.uploadFileStorage[groupName][buildingPartName][i].saved = saved;
    },

    [SAVE_IMG_DOCID](state, { groupName, buildingPartName, i, docId }) {
        if (
            !(groupName in state.uploadFileStorage)
            || !(buildingPartName in state.uploadFileStorage[groupName])
            || !(i in state.uploadFileStorage[groupName][buildingPartName])
        ) {
            return;
        }

        state.uploadFileStorage[groupName][buildingPartName][i].documentId = docId;
    },

    [SAVE_IMG_DIRTY](state, { groupName, buildingPartName, i, dirty }) {
        if (
            !(groupName in state.uploadFileStorage)
            || !(buildingPartName in state.uploadFileStorage[groupName])
            || !(i in state.uploadFileStorage[groupName][buildingPartName])
        ) {
            return;
        }

        state.uploadFileStorage[groupName][buildingPartName][i].dirty = dirty;
    },

    async [UPLOAD_SAVED_FILES](state, clientId) {
        Object.keys(state.uploadFileStorage).forEach((groupName) => {
            Object.keys(state.uploadFileStorage[groupName]).forEach(async (buildingPartName) => {
                for (var i = 0; i < state.uploadFileStorage[groupName][buildingPartName].length; i++) {
                    var img = state.uploadFileStorage[groupName][buildingPartName][i];
                    if (img.saved == false || img.saved == undefined) {
                        let formData = new FormData();
                        formData.append('files[0]', img.file);
                        let response = await axios.post(
                            '/api/v1/documents/uploadUserPlanDocumentByAdvisor',
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    description: img.comment,
                                    groupName: groupName,
                                    subject: buildingPartName,
                                    clientId: clientId
                                }
                            }
                        );

                        if (response.status >= 200 && response.status < 300) {
                            img.saved = true;
                            img.documentId = response.data[0].document.id;
                        }
                    } else if (img.saved == true && img.dirty == true) {
                        let response = await axios.post(
                            '/api/v1/documents/updateFileComment/' + img.documentId,
                            {},
                            {
                                headers: {
                                    description: img.comment
                                }
                            }
                        );
                        if (response.status >= 200 && response.status < 300) {
                            img.dirty = false;
                        }
                    }
                }
            });
        });
    },

  [UPDATE_CONSUMER_SITUATION](state, consumerSituation) {
    consumerProperties.forEach((p) => {
      state[p] = consumerSituation[p];
    });
  },

  [SET_CURRENT_SITUATION_USER_EDITABLE](state, clientId) {
    let client = state.clients.find(x => x.user.id === clientId);
    if (client != null)
      client.currentSituationUserEditable = true;
  }
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
