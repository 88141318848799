import axios from 'axios';
axios.defaults.withCredentials = true;

export default {
  loadSettings() {
    return axios.get('/api/v1/settings').then(({ data }) => data).then((data) => {
      if (process.env.NODE_ENV !== 'production') {
        data.urls.createPlanUrl = '/home/plan-maken/';
        data.urls.quickScanUrl = '/quickscan/';
        data.urls.definitivePlanUrl = '/definitive-plan/';
      }

      return data;
    });
  },
  loadQuickScanMeasures() {
    return axios.get('/api/v1/measures/quickscan').then(({ data }) => data);
  },
  loadQuickScanDataTypes() {
    return {
      buildingTypes: [
        { id: 1, text: '2-onder-1 kapwoning met kap' },
        { id: 2, text: '2-onder-1 kapwoning met plat dak' },
        { id: 3, text: 'hoekwoning met kap' },
        { id: 4, text: 'hoekwoning met plat dak' },
        { id: 5, text: 'tussenwoning met kap' },
        { id: 6, text: 'tussenwoning met plat dak' },
        { id: 7, text: 'vrijstaande woning met kap' },
        { id: 8, text: 'vrijstaande woning met plat dak' }
      ],
      energyEfficiencies: [
        {
          id: 1,
          text: 'Heel zuinig'
        },
        {
          id: 2,
          text: 'Normaal'
        },
        {
          id: 3,
          text: 'Groot verbruiker'
        }
      ],
      buildYears: [
        { id: 1, text: 'voor 1940' },
        { id: 2, text: "jaren '40 tot '75" },
        { id: 3, text: "jaren '76 tot '85" },
        { id: 4, text: "jaren '86 tot '95" },
        { id: 5, text: 'na 1995' }
      ],
      lifeStages: [{ id: 1, text: '20-50 jaar' }, { id: 2, text: '50-75 jaar' }, { id: 3, text: '75+' }]
    };
  },
  loadAnnualConsumption(zipCode, houseNumber) {
    return axios.get(`/api/v1/annual-consumptions/${zipCode}/${houseNumber}`).then(({ data }) => data);
  },
  getMeasure(id) {
    return axios.get(`/api/v1/measures/${id}`).then(({ data }) => data);
  },
  createProvisionalPlan(data) {
    if (data == null || data == undefined || data.measures == null) {
      return null;
    }
    // clone it, so we don't alter the store's state
    let dataToPost = Object.assign({}, data);

    //transform the measures property
    dataToPost.measures = Object.keys(dataToPost.measures).map((key) => {
      return { key, value: data.measures[key] };
    });

    return axios.post('/api/v1/plans/create', dataToPost).then((response) => {
      return response.data;
    });
  },
  loadCurrentUser() {

    return axios.get('/api/v1/clients/current').then(({ data }) => {
      data.fullName = data.firstName + ' ' + data.lastName;
      return data;
    });
  },
  loadCurrentPlan() {
    //Apparently, Axios getters do not deserialize 0 correctly and produce undefined, so i'm deserializing the JSON manually with JSON.parse.
    return axios.get('/api/v1/plans/current').then((result) => { if (result == null || result == undefined) { return null; } return JSON.parse(result.request.response); });
  },
  loadCurrentPlanState() {
    return axios.get('/api/v1/plans/current').then(({ data }) => data.state).catch(() => {
      return 'unknown';
    })
  },
  loadCustomComment(userId) {
    return axios.get(`/api/v1/plans/custom-comment/${userId}`).then(({ data }) => data);
  },
  editCustomComment(userId, customComment) {
    return axios.post(`/api/v1/plans/edit-comment/${userId}`,
      {
        customComment: customComment,
      }).then(({ data }) => data);
  },
  loadAdvicePlan(userId = null) {
    if (userId != null) {
      return axios.get('/api/v1/plans/current/' + userId).then((response) => {
        if (response.status == 200 && response.data.type != 'UserPlan') {
          return JSON.parse(response.request.response);
        }
        return "";
      }).catch(
        () => {
          let index = window.location.href.search('/[0-9]+$');
          window.location.href = window.location.href.substring(0, index + 1);
        });
    }
    return axios.get('/api/v1/plans/current/advice-plan').then((response) => {
      if (response.status == 200 && response.data.type != 'UserPlan') {
        return JSON.parse(response.request.response);
      }
      return "";
    });
  },
  loadCurrentAppointment() {
    return axios.get('/api/v1/appointments/current').then(({ data }) => data);
  },
  loadAppointment(userId) {
    return axios.get('/api/v1/appointments/' + userId).then(({ data }) => data).catch(() => {
      return null;
    });
  },
  loadAllAppointments() {
    return axios.get('/api/v1/appointments').then(({ data }) => {
      return data.map((appointment) => {
        appointment.fullName = appointment.firstName + ' ' + appointment.lastName;
        return appointment;
      });
    }).catch(() => {
      return null;
    });
  },
  loadAppointmentsForAdvisor(advisorId) {
    return axios.get(`api/v1/advisors/${advisorId}/appointments`).then(({ data }) => {
      return data.map((appointment) => {
        appointment.fullName = appointment.firstName + ' ' + appointment.lastName;
        return appointment;
      });
    });
  },


  updateProfileAdvisor(advisorId, motivation, available, contactPreference) {
    return axios.post(`api/v1/advisors/${advisorId}/profile`,
      {
        motivation: motivation,
        available: available,
        contactPreference: contactPreference

      }).then(({ data }) => data);
  },


  sendCallMeBackRequest(data) {
    return axios.post('api/v1/call-me-back', data);
  },
  loadActionableClients() {
    return axios.get('/api/v1/clients/actionable').then(({ data }) => {
      return data.map((client) => {
        client.user.fullName = client.user.firstName + ' ' + client.user.lastName;
        return client;
      });
    });
  },
  loadActionableClientsForAdvisor(advisorId) {
    return axios.get(`/api/v1/advisors/${advisorId}/clients/actionable`).then(({ data }) => data);
  },
  loadAdvisors() {
    return axios.get('/api/v1/advisors').then(({ data }) => data);
  },
  loadAdvisor(id) {
    return axios.get('/api/v1/advisors/' + id).then(({ data }) => data);
  },
  loadAdvisorName(id) {
    return axios.get('/api/v1/advisors/' + id + '/advisor-name').then(({ data }) => data);
  },
  setAdvisor(userId, advisorId) {
    return axios.post(`api/v1/advisors/setAdvisor/${userId}/${advisorId}`).then(({ data }) => data);
  },
  loadActiveClients() {
    return axios.get('/api/v1/clients/active').then(({ data }) => {
      return data.map((client) => {
        client.user.fullName = client.user.firstName + ' ' + client.user.lastName;
        return client;
      });
    });
  },
  loadActiveClientsForAdvisor(advisorId) {
    return axios.get(`/api/v1/advisors/${advisorId}/clients`).then(({ data }) => {
      return data.map((client) => {
        client.user.fullName = client.user.firstName + ' ' + client.user.lastName;
        return client;
      });
    });
  },
  planAppointment(user, date) {
    return axios.put(`/api/v1/clients/${user.id}/appointment`, { date: date });
  },

  deleteAppointment(user, date) {
    return axios.delete(`/api/v1/clients/${user.id}/delete-appointment`, { date: date });
  },
  loadClientPlan(userId) {
    return axios.get(`/api/v1/clients/${userId}/plan`).then(({ data }) => data);
  },

  loadClient(userId) {
    return axios.get(`/api/v1/clients/${userId}`).then(({ data }) => data);
  },

  loadBuildingParts(order) {
    return axios.get(`/api/v1/building-parts/${order}`).then(({ data }) => data);
  },

  loadBuildingPartCategories() {
    return axios.get('/api/v1/building-part-categories/').then(({ data }) => data);
  },

  loadDimensions() {
    return axios.get('/api/v1/dimensions').then(({ data }) => data);
  },

  loadAdvisorMeasures(virtual) {
    let url = '/api/v1/measures/advisor';
    if (virtual) {
      url = '/api/v1/measures/advisor/virtual';
    }
    return axios.get(url).then(({ data }) => data);
  },

  loadAdvisorEapMeasures(virtual) {
    let url = '/api/v1/measures/advisor/eap';
    if (virtual) {
      url = '/api/v1/measures/advisor/virtual';
    }
    return axios.get(url).then(({ data }) => data);
  },

  loadAdvisorPostalCode(postalCode) {
    return axios.get('/api/v1/advisors/postalcode/' + postalCode).then(({ data }) => data);
  },

  startAdvice(userId) {
    return axios.post(`/api/v1/plans/user-plan/${userId}/start-advice`).then((response) => {
      return response.data;
    });
  },
  updateAdvisePlan(data) {
    // clone it, so we don't alter the store's state
    let dataToPost = Object.assign({}, data);

    // //transform the measures property
    // dataToPost.consumerSituation.measures = Object.keys(data.consumerSituation.measures).map((key) => {
    //   return Object.keys(data.consumerSituation.measures[key]).map((group) => {
    //     return { key, group, value: data.consumerSituation.measures[key][group] };
    //   });
    // }).flat();

    dataToPost.dimensions = Object.keys(dataToPost.dimensions).map((key) => {
      return { key, value: data.dimensions[key] };
    });

    return axios.post(`/api/v1/plans/${data.userId}/update`, dataToPost).then((response) => {
      return response.data;
    });
  },
  proposeMeasures(userId) {
    return axios.post(`/api/v1/plans/user-plan/${userId}/propose-measures`).then((response) => {
      return response.data;
    });
  },
  getAllPartners() {
    return axios.get(`/api/v1/partner/get-all`).then((response) => {
      return response.data;
    });
  },
  getPartner(PartnerId) {
    return axios.get(`/api/v1/partner/${PartnerId}/get-partner`).then((response) => {
      return response.data;
    });
  },

  PostClientFiles() {
    return axios.post(`/api/v1/documents/uploadUserPlanDocumentByUser`).then((response) => {
      return response.data;
    });
  },

  getFilesOfCurrentUser() {
    return axios.get(`/api/v1/documents/getDocumentsOfCurrentUser`).then((response) => {
      return response.data;
    });
  },
  getFilesOfUser(Id) {
    return axios.get(`/api/v1/documents/getDocumentsOfAdvisorsClient/any/${Id}`).then((response) => {
      return response.data;
    });
  },
  getSharedDocuments(clientId = null) {
    if (clientId != null) {
      return axios.get(`/api/v1/documents/getSharedDocuments`,
        {
          headers: {
            clientId: clientId
          }
        }).then(({ data }) => data);
    } else {
      return axios.get(`/api/v1/documents/getSharedDocuments`).then(({ data }) => data);
    }
  },

  deleteFile(Id) {
    return axios.get(`/api/v1/documents/${Id}/deleteDocument`).then((response) => {
      return response.data;
    });
  },

  get(url) {
    return axios.get(url).then((response) => {
      return response.data;
    });
  },

  getCurrentOrderStatus() {
    return this.get('/api/v1/payment/status');
  },

  getAdvisorDocuments(planId) {
    return this.get('/api/v1/documents/GetAdvisorDocuments/' + planId);
  },

  getAdvisorDocumentsByUserId(userId) {
    return this.get('/api/v1/documents/getAdvisorDocumentsByUserId/' + userId);
  },

  patch(url, patchData = null, headers = null) {

    return axios.patch(url,
      patchData,
      {
        headers: headers,
      })
      .then(response => response.data)
      .catch(error => error.response.data);
  },

  put(url, putData = null, headers = null) {

    return axios.put(url,
      putData,
      {
        headers: headers,
      })
      .then(response => response.data)
      .catch(error => error.response.data);
  },

  updateFileComment(fileId, comment) {
    return this.post('/api/v1/documents/updateFileComment/' + fileId,
      null,
      {
        description: comment
      });
  },

  setSelectedAdvicePlanMeasures(userId, term, measures) {
    return this.post('/api/v1/plans/advice-plan/' + userId + '/finish/terms/' + term + '/measures', measures);
  },

  getSelectedAdvicePlanMeasures(userId, term) {
    return axios.get('/api/v1/plans/advice-plan/' + userId + '/finish/terms/' + term + '/measures').then(function (response) {
      if (response.status == 204)
        return [];
      if (response.status == 200)
        return response.data;
      throw 'Request to /api/v1/plans/advice-plan/' + userId + '/finish/terms/' + term + '/measures failed.';
    });
  },

  requestIDealUrl() {
    return this.post('/api/v1/payment/start-ideal-transaction');
  },

  getVoucherGroups() {
    return this.get('/api/v1/payment/vouchers/groups').then((response) => response);
  },

  createVoucherGroup(name, validFrom, validTo, discount, amount) {

    return this.post('/api/v1/payment/vouchers/groups', {
      name: name,
      validFrom: validFrom,
      validTo: validTo,
      discount: discount,
      amount: amount,
    });
  },

  post(url, postData = null, headers = null) {
    return axios.post(url,
      postData,
      {
        headers: headers
      })
      .then(response => response.data)
      .catch(error => error.response.data);
  },

  getVoucherGroup(groupId) {
    return this.get(`/api/v1/payment/vouchers/group/${groupId}`);
  },

  updateVoucherGroup(groupId, name, validFrom, validTo) {

    let data = {};

    if (name)
      data.name = name;

    if (validFrom)
      data.validFrom = validFrom;

    if (validTo)
      data.validTo = validTo;

    return this.patch(`/api/v1/payment/vouchers/groups/${groupId}`, data);
  },

  addVouchersToGroup(groupId, amount) {

    return this.put(`/api/v1/payment/vouchers/groups/${groupId}`, {
      amount: amount,
    });
  },

  getVouchersExportUrl(groupId, fileType) {

    return `/api/v1/payment/vouchers/groups/${groupId}/export/${fileType}`;
  },

  getOpenBankTransfers() {
    return this.get('/api/v1/payment/bank-transfers').then((response) => response);
  },

  getOpenIdealTransfers() {
    return this.get('/api/v1/payment/ideal-transfers').then((response) => response);
  },

  setBankTransferReceived(userId) {
    return this.post(`/api/v1/payment/bank-transfers/complete/${userId}`);
  },

  setIdealTransferReceived(userId) {
    return this.post(`/api/v1/payment/ideal-transfers/complete/${userId}`);
  },

  getPaymentStatus() {
    return this.get('/api/v1/payment/status');
  },

  activateVoucher(voucherCode) {
    return this.post('/api/v1/payment/vouchers/activate', { voucherCode: voucherCode });
  },

  deactivateVoucher() {
    return this.post('/api/v1/payment/vouchers/deactivate');
  },

  startManualTransaction() {
    return this.post('/api/v1/payment/start-bank-transfer');
  },

  cancelTransaction() {
    return this.post('/api/v1/payment/cancel-transaction');
  },

  payUsingVoucher() {
    return this.post('/api/v1/payment/pay-using-voucher');
  },

  getAddressInfo(postalCode, houseNumber) {
    return this.get('/api/v1/postal-code/' + postalCode + "/" + houseNumber);
  },

    getRealisationPartnerCategories(advicePlan) {

        return this.post('/api/v1/quotation/realization-partner-options', advicePlan);
    },

    /**
     * @param {Array} chosenPartners
     */
    setRealizationPartners(chosenPartners) {

        return this.post('/api/v1/quotation/set-realization-partners', chosenPartners);
    },

    /**
     * @returns {Object[]}
     */
    getQuotations() {

        // TODO: Get quotations
        return [];
    },

    /**
     * @param {Date} startDate
     * @param {Date} endDate
     * 
     * @returns {string}
     */
    getQuotationsExportUrl(startDate, endDate) {


        // TODO: Get quotation export URL with start- and end-date
        return '';
    },
}
