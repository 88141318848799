import api from '../../api';
import { getField, updateField } from 'vuex-map-fields';
import { filter } from 'lodash';

const state = {
	plan: {}
};

const getters = {

	getField,

	plan(state) {

		return state.plan;
	},
};

const actions = {
  async create({ commit }, data) {
    if (data.measures != null) {
      await api.createProvisionalPlan(data).then((data) => {
        commit('createProvisionalPlan', data);
        commit('removeZeroAmountMeasures');
      }).catch(() => { return null });
      return;
    }
    commit('createProvisionalPlan', { terms: [{ Term: 0, measures: [] }, { term: 1, measures: [] }, { term: 2, measures: [] }] });
    return { terms: [{ term: 0, measures: [] }, { term: 1, measures: [] }, { term: 2, measures: [] }] };
  },

	setPlan({ commit }, plan) {

		commit('plan', plan);
    },
};

const mutations = {
	updateField,
	createProvisionalPlan(state, plan) {
		state.plan = plan;
	},
	removeZeroAmountMeasures(state) {
		state.plan.terms.forEach((term) => {
			term.measures = filter(term.measures, (measure) => measure.amount > 0) || [];
		});
	},

	plan(state, plan) {

		state.plan = plan;
    },
};

export default {
	namespaced: true,
	getters,
	state,
	actions,
	mutations
};
