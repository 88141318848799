<template>
    <span :class="tooltipClasses" :title="title" @click.prevent="showOverlay()">
        {{ hasIcon ? icon : text }}
    </span>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default {
    data() {
        return {
            body: null
        };
    },
    props: {
        title: String,
        icon: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        }
    },
    computed: {
        hasIcon() {
            return this.icon.length
        },
        tooltipClasses() {
            return {
                'tooltip': true,
                'tooltip-icon': this.hasIcon,
                'tooltip--text': !this.hasIcon,
            }
        }
    },
    methods: {
        ...mapActions('ui', ['openTooltipOverlay']),
        showOverlay() {
            this.openTooltipOverlay({
                title : this.title,                 
                body: this.body.$el.outerHTML
            });
        }
    },
    mounted() {            
        var vnode = this.$slots.default[0];
        var node = Vue.extend({
            props: ["node"],
            render() {
                return this.node ? this.node : "";
            }
        });

        const render = new node({
            propsData: {
                node: vnode
            }
        });

        render.$mount();
        this.body = render;
    }
}
</script>