<template>
  <div class="hamburger" @click.prevent="toggleSideNav()">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("ui", ["toggleSideNav"])
  }
};
</script>

