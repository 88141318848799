import api from '~/src/api';

const state = {
	advicePlan: null,
  quotationPlan: null
};

const getters = {

	advicePlan(state) {

		return state.advicePlan;
	},

	quotationPlan(state) {

		return state.quotationPlan;
    },
};

const actions = {

	async loadAdvicePlan({ commit }) {

		await api.loadAdvicePlan().then((advicePlan) => {

			advicePlan.plan = JSON.parse(advicePlan.plan);

			commit('advicePlan', advicePlan.plan);
		});
	},

	async createQuotationPlan({ state, dispatch, commit, getters }) {

		if (state.quotationPlan)
			return;

		await dispatch('loadAdvicePlan');

		commit('quotationPlan', getters.advicePlan)
    },

  saveQuotationPlan({ commit }, plan) {
    commit('saveQuotationPlan', plan);
  }
};

const mutations = {

	advicePlan(state, advicePlan) {

		state.advicePlan = advicePlan;
	},

	quotationPlan(state, quotationPlan) {

		state.quotationPlan = quotationPlan;
    },

  saveQuotationPlan(state, plan) {
    state.quotationPlan = plan;
  }
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};