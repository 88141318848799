<template>
  <section class="max-width-medium content" style="border-bottom:100px solid transparent;">
    <div class="intro">
      <span class="home-part-icon">
        <img :src="iconUrl">
      </span>
      <div v-if="groupName">
        <h6>{{category}} per bouwdeel</h6>
        <h3>{{groupName}}</h3>
      </div>
      <div v-else>
        <h6>{{category}}</h6>
      </div>
      <p>
        Loop door de woning en vul dit formulier in voor een optimale scan.
        <br>We starten de woningscan op de zolder. Start met het maken van een aantal foto’s en meet vervolgens alle maten op.
      </p>
    </div>
    
    <form @submit.prevent="submit">
     

      <AdvisePlanEdit
        :group-name="groupName"
        :category="category"
        :schema="formSchema"
        :measures="measuresToSet"
        :dimensions="dimensionsToSet"
        :readonly="readonly"
        @update-measure="setMeasure"
        @update-dimension="setDimension"
      ></AdvisePlanEdit>

      <div class="sticky-footer light">
        <div class="btn-row max-width-medium">
          <button class="btn dark right" :disabled="$wait.any">Verder</button>
          <a class="btn light left" @click.prevent="goBack()">Terug</a>
          <FooterNavDropdown v-if="!isUserDashboard" :label="getCurrentRoute().display"></FooterNavDropdown>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import AdvisePlanEdit from "../components/AdvisePlanEdit.vue";
import FooterNavDropdown from "../components/FooterNavDropdown.vue";
import { mapWaitingActions } from "vue-wait";
import { clone } from "lodash";
import schemaBuilder from "../../../store/modules/schema-builder";
import { routes } from "../step2-routes";
import { mapState } from "vuex";
import { findIndex } from "lodash";

export default {
  name: "AdvicePlanStep2",
  props: {
    groupName: {
      type: String,
      default: () => ""
    },
    category: {
      type: String,
      default: () => ""
    },
    isUserDashboard: {
      type: Boolean,
      required: false,
      default: () => false
      },
    readonly: {
        type: Boolean,
        required: false,
        default: () => false,
    }
  },
  components: {
    AdvisePlanEdit,
    FooterNavDropdown,
  },
  data() {
    return {
      formSchema: null,
      dimensionsToSet: null,
      measuresToSet: null
    };
  },
  methods: {
      ...mapWaitingActions("advisorDashboard", ["updateAdvisePlanSituation", "uploadSavedFilesAsync"]),
    async submit() {
      if(this.isUserDashboard)
          await this.update('next');
      else
          this.$router.push({ name: this.nextRoute.name, query: { readonly: this.readonly.toString() }});
    },
    async goBack() {
      if(this.isUserDashboard)
          await this.update('back');
      else
          this.$router.go(-1);
    },
    getCurrentRoute() {
      return routes[this.getCurrentRouteIndex()];
    },
      getCurrentRouteIndex() {
      var self = this;
      return findIndex(routes, r => {
          if (self.groupName)
          return (
              r.props({ query: {} }).groupName == self.groupName &&
              r.props({ query: {} }).category == self.category
          );

        return (
            r.props({ query: {} }).groupName == undefined && r.props({ query: {} }).category == self.category
        );
      });
    },
    async update(event) {
        let result = this.$validator.validateAll();

        if (!result) {
            return false;
        }
        await this.updateAdvisePlanSituation({
            measures: this.measuresToSet,
            dimensions: this.dimensionsToSet
        });

        await this.uploadSavedFilesAsync(this.$route.params.id);
        if(this.isUserDashboard)
            this.$emit(event);
        return true;
    },
    async loadFromClientPlan() {
        let schema = await schemaBuilder.build(
            this.clientPlan,
            this.category,
            this.groupName
        );
        this.formSchema = schema;
        this.measuresToSet = clone(schema.selectedMeasures);
        this.dimensionsToSet = clone(schema.dimensionValues);
    },
    setMeasure(buildingPartId, groupName, measureId) {
        if (measureId == null && this.measuresToSet[buildingPartId] !== undefined && this.measuresToSet[buildingPartId] !== null && this.measuresToSet[buildingPartId][groupName] !== undefined) {
            delete this.measuresToSet[buildingPartId][groupName];
            return;
        }

        if (this.measuresToSet[buildingPartId] === undefined || this.measuresToSet[buildingPartId] === null)
            this.measuresToSet[buildingPartId] = {};
        this.measuresToSet[buildingPartId][groupName] = measureId;
    },
    setDimension(dimensionId, dimensionValue) {
        this.dimensionsToSet[dimensionId] = dimensionValue;
    }
  },
  watch: {
    async areaOfUse() {
      // TODO: update the plan and recalculate the dimensions
      },
      clientPlan: {
          async handler(val) {
              await this.loadFromClientPlan();
          },
          deep: true,
      },
    async category() {
        if(this.isUserDashboard)
            await this.loadFromClientPlan();
    },
    async groupName() {
        if(this.isUserDashboard)
            await this.loadFromClientPlan();
    }
  },
  async mounted() {
      await this.$store.dispatch("advisorDashboard/loadVirtualMeasures");
      if(this.clientPlan !== null && this.clientPlan !== undefined)
          await this.loadFromClientPlan();
  },
  computed: {
    ...mapState("advisorDashboard", [
      "clientPlan",
      "areaOfUse",
      "buildingType"
    ]),
    nextRoute() {
      let currentRouteIdx = this.getCurrentRouteIndex();
      if (routes.length > currentRouteIdx + 1) {
        let nextRoute = routes[currentRouteIdx + 1];
        return nextRoute;
      }

      return {
        name: "finish:short"
      };
    },
    prevRoute() {
      let currentRouteIdx = this.getCurrentRouteIndex();
      if (currentRouteIdx - 1 >= 0) {
        let prevRoute = routes[currentRouteIdx - 1];
        return prevRoute;
      }
      return {
        name: "advise-plan-step1"
      };
      },
    iconUrl: function() {
        let term = (this.groupName ? this.groupName : this.category).trim().toLowerCase();
        let baseUrl ='/assets/icons/'
        switch (term) {
            case 'zolder':
                return baseUrl + 'zolder-large.svg';
            case 'verdieping':
                return baseUrl + 'verdieping-large.svg';
            case 'begane grond':
                return baseUrl + 'begane-grond-large.svg';
            case 'installatie':
                return baseUrl + 'installatie-large.svg';
            default:
                return baseUrl + 'icon-analyse.svg';
        }
    }
  },

    async beforeRouteLeave(to, from, next) {

        let result = await this.update(null);

        next(result);
    },
};
</script>
