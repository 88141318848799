<template>
    <li :class="['file-uploader-file-component', { 'file-uploader-file-component--open': open }]">

        <div class="file-uploader-file-component__summary">

            <span class="file-uploader-file-component__link" v-if="isImage(file.extension)">

                <span class="file-uploader-file-component__image-container" @click="showLightbox(file.lightboxIndex)">

                    <img :src="file.url" alt="" class="file-uploader-file-component__image" />

                    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" class="file-uploader-file-component__zoom-icon">
                        <g id="Adviseurs-portaal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Plan-afronden-04" transform="translate(-683.000000, -576.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                <g id="ic_zoom_in_24px" transform="translate(683.000000, 576.000000)">
                                    <path d="M12.5,11 L11.71,11 L11.43,10.73 C12.4438721,9.55402182 13.0011023,8.05269443 13,6.5 C13,2.91014913 10.0898509,-8.8817842e-16 6.5,0 C2.91014913,8.8817842e-16 -1.77635684e-15,2.91014913 -1.77635684e-15,6.5 C-2.22044605e-15,10.0898509 2.91014913,13 6.5,13 C8.11,13 9.59,12.41 10.73,11.43 L11,11.71 L11,12.5 L16,17.49 L17.49,16 L12.5,11 Z M6.5,11 C4.01,11 2,8.99 2,6.5 C2,4.01 4.01,2 6.5,2 C8.99,2 11,4.01 11,6.5 C11,8.99 8.99,11 6.5,11 Z M9,7 L7,7 L7,9 L6,9 L6,7 L4,7 L4,6 L6,6 L6,4 L7,4 L7,6 L9,6 L9,7 Z" id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </svg>

                </span>

            </span>

            <a :href="file.url" class="file-uploader-file-component__link" v-else>

                <span class="file-uploader-file-component__icon-container" :data-extension="file.extension ? file.extension.substr(1) : false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="file-uploader-file-component__icon">
                        <path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"></path>
                    </svg>
                </span>

            </a>

            <a :href="file.url" class="file-uploader-file-component__link file-uploader-file-component__link--name">

                <span class="file-uploader-file-component__name">
                    {{ file.fileName }}
                </span>

            </a>
            
            <span class="file-uploader-file-component__toggle-button" v-if="showButton || file.description.length" @click="toggleOpen">

                <span class="file-uploader-file-component__delete-button" v-if="showButton" @click.stop="confirmDelete(file.id)" title="Verwijderen">
                    &times;
                </span>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436.7 265" class="file-uploader-file-component__toggle-icon">
                    <path d="M201.4,258L7,63.6c-9.4-9.4-9.4-24.6,0-33.9L29.7,7c9.4-9.4,24.5-9.4,33.9,0l154.7,154L373.1,7c9.4-9.3,24.5-9.3,33.9,0 l22.7,22.7c9.4,9.4,9.4,24.6,0,33.9L235.3,258C225.9,267.4,210.7,267.4,201.4,258L201.4,258z" />
                </svg>

            </span>

        </div>

        <div class="file-uploader-file-component__details">

            <textarea class="file-uploader-file-component__description" :readonly="!showButton" @change="saveDescription" @input="debounceSaveDescription">{{ file.description }}</textarea>

            <div class="file-uploader-file-component__description-status">

                <img src="/assets/img/spinner-pink.gif"
                     alt="Laden"
                     class="file-uploader-file-component__description-status-icon"
                     v-if="descriptionSaving" />

                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 18 15"
                     class="file-uploader-file-component__description-status-icon"
                     v-else-if="descriptionSaved">
                    <defs><path id="72mwa" d="M588.88 1725l-8.43 8.68-3.32-3.42-1.13 1.15 4.45 4.59 9.55-9.84z" /></defs>
                    <g><g transform="translate(-574 -1723)"><use fill="#e64b72" xlink:href="#72mwa" /><use fill="#fff" fill-opacity="0" stroke="#e64b72" stroke-miterlimit="50" stroke-width="2" xlink:href="#72mwa" /></g></g>
                </svg>

                <span class="file-uploader-file-component__description-status-text">
                    <template v-if="descriptionSaving">
                        Bijschrift opslaan...
                    </template>
                    <template v-else-if="descriptionSaved">
                        Bijschrift opgeslagen
                    </template>
                    <template v-else>
                        Bijschrift wordt automatisch opgeslagen
                    </template>
                </span>

            </div>

        </div>

    </li>
</template>

<script>
    import api from "../api";
    import _ from 'lodash';

    export default {
        data() {
            return {
                open: false,
                descriptionSaving: false,
                descriptionSaved: false,
            }
        },
        props: {
            file: Object,
            showButton: Boolean,
            imageExtensions: Array,
        },
        methods: {

            showLightbox(lightboxIndex) {
                this.$emit('showLightbox', lightboxIndex);
            },

            toggleOpen() {
                this.open = !this.open;
            },

            isImage(fileExtension) {
                return this.imageExtensions.includes(fileExtension.toLowerCase());
            },

            confirmDelete(id) {
                if (window.confirm("weet u zeker dat u dit bestand wilt verwijderen?")) {
                    this.$emit('delete-file', id);
                }
            },

            debounceSaveDescription: _.debounce(function (event) {
                this.saveDescription(event);
            }, 500),

            saveDescription: async function (event) {

                if (!this.showButton) {
                    return;
                }

                this.descriptionSaved = false;
                this.descriptionSaving = true;

                let updateFileComment = api.updateFileComment(this.file.id, event.target.value);

                setTimeout(() => {
                    updateFileComment.then(() => {
                        this.descriptionSaving = false;
                        this.descriptionSaved = true;
                    });
                }, 500);
            },
        },
    };
</script>

<style lang="scss" scoped>
    /*************************************************
     * COMPONENT CSS STAAT IN ASSETS/SCSS/COMPONENTS *
     *************************************************/
</style>