<template>
    <aside :class="['lightbox-component', { 'lightbox-component--visible': lightboxVisible }]">

        <header class="lightbox-component__header">
            <div class="lightbox-component__close-button" @click="close">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 352" class="lightbox-component__close-icon">
                    <path d="M242.7,176L342.8,75.9c12.3-12.3,12.3-32.2,0-44.5L320.6,9.2c-12.3-12.3-32.2-12.3-44.5,0L176,109.3L75.9,9.2 C63.7-3.1,43.7-3.1,31.5,9.2L9.2,31.4c-12.3,12.3-12.3,32.2,0,44.5L109.3,176L9.2,276.1c-12.3,12.3-12.3,32.2,0,44.5l22.2,22.2 c12.3,12.3,32.2,12.3,44.5,0L176,242.7l100.1,100.1c12.3,12.3,32.2,12.3,44.5,0l22.2-22.2c12.3-12.3,12.3-32.2,0-44.5L242.7,176z" />
                </svg>
            </div>
        </header>

        <figure class="lightbox-component__image-container" @touchstart="updateTouchPosition($event)" @touchend="updateTouchDirection($event)">
            <img :src="currentImage.src" alt="" class="lightbox-component__image" v-if="currentImage" />
        </figure>

        <nav class="lightbox-component__arrows-container" v-if="images.length > 1">

            <span class="lightbox-component__arrow-container lightbox-component__arrow-container--prev" @click="prev">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 436.7" class="lightbox-component__arrow-icon">
                    <path d="M7,201.4L201.4,7c9.4-9.4,24.6-9.4,33.9,0L258,29.7c9.4,9.4,9.4,24.5,0,33.9L104,218.3l154,154.8c9.3,9.4,9.3,24.5,0,33.9 l-22.7,22.7c-9.4,9.4-24.6,9.4-33.9,0L7,235.3C-2.3,225.9-2.3,210.7,7,201.4z" />
                </svg>
            </span>

            <span class="lightbox-component__arrow-container lightbox-component__arrow-container--next" @click="next">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 436.7" class="lightbox-component__arrow-icon">
                    <path d="M258,235.3L63.6,429.7c-9.4,9.4-24.6,9.4-33.9,0L7,407c-9.4-9.4-9.4-24.5,0-33.9l154-154.7L7,63.6c-9.3-9.4-9.3-24.5,0-33.9 L29.7,7c9.4-9.4,24.6-9.4,33.9,0L258,201.4C267.4,210.7,267.4,225.9,258,235.3z" />
                </svg>
            </span>

        </nav>

        <div class="lightbox-component__background" @click="close"></div>

    </aside>
</template>

<script>
    import Vue from 'vue';

    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            images: {
                type: Array,
                default: () => [],
            },
            current: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                touchPosition: {
                    x: null,
                    y: null,
                },
                touchDirection: null,
            };
        },
        methods: {

            close() {
                this.$emit('update:visible', false);
            },

            prev() {
                var newCurrent = this.current - 1;
                this.$emit('update:current', newCurrent in this.images ? newCurrent : this.images.length - 1);
            },

            next() {
                var newCurrent = this.current + 1;
                this.$emit('update:current', newCurrent in this.images ? newCurrent : 0);
            },

            updateTouchPosition(event) {
                var touchPosition = event.touches[0];

                this.touchPosition.x = touchPosition.clientX;
                this.touchPosition.y = touchPosition.clientY;
            },

            updateTouchDirection(event) {
                var touchPosition = event.changedTouches[0],
                    xDiff = touchPosition.clientX - this.touchPosition.x,
                    yDiff = touchPosition.clientY - this.touchPosition.y;
                
                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    
                    if (xDiff > 10) {
                        this.touchDirection = 'right';
                    }

                    else if (xDiff < -10) {
                        this.touchDirection = 'left';
                    }
                }
                else {
                    
                    if (yDiff > 10) {
                        this.touchDirection = 'up';
                    }

                    else if (yDiff < -10) {
                        this.touchDirection = 'down';
                    }
                }

                Vue.nextTick(this.resetTouchDirection);
            },

            resetTouchDirection() {
                this.touchDirection = null;
            },
        },
        computed: {

            lightboxVisible() {
                return this.visible && this.images.length;
            },

            currentImage() {
                return this.current in this.images ? this.images[this.current] : null;
            },
        },
        created() {

            document.addEventListener('keyup', (event) => {
                switch (event.keyCode) {

                    case 27:

                        this.close();
                        break;

                    case 37:

                        this.prev();
                        break;

                    case 39:

                        this.next();
                        break;
                }
            });
        },
        watch: {
            touchDirection(direction) {
                
                switch (direction) {

                    case 'left':

                        this.prev();
                        break;

                    case 'right':

                        this.next();
                        break;
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    /*************************************************
     * COMPONENT CSS STAAT IN ASSETS/SCSS/COMPONENTS *
     *************************************************/
</style>