<template>
    <section class="max-width finish-plan" style="margin-top:118px;">
        <div class="quad-col">
            <div class="triple col">
                <img src="/assets/img/spinner.gif" style="margin: auto; display: block;" />
                <div class="content" v-if="loaded" style="display: none !important">
                    <div class="plan-header">
                        <!-- <div class="blue-block right">
                          <b>€115</b>
                          Besparings potentieel fase 1 per maand
                        </div>-->
                        <h2>Advies Plan</h2>
                        <p>Doel: {{clientPlan.consumerSituation.reason|reasonDisplay}} en {{clientPlan.consumerSituation.ambition | ambitionDisplay}}</p>
                    </div>
                    <BaseAccordion class="product-list" :calculate-height="false">
                        <AdvicePlanDetailTerm :term="0"
                                              :measures="proposedMeasures[0]"
                                              :all-measures="allMeasures"
                                              :total-costs-changed="totalsChanged" />
                        <AdvicePlanDetailTerm :term="1"
                                              :measures="proposedMeasures[1]"
                                              :all-measures="allMeasures"
                                              :total-costs-changed="totalsChanged" />
                        <AdvicePlanDetailTerm :term="2"
                                              :measures="proposedMeasures[2]"
                                              :all-measures="allMeasures"
                                              :total-costs-changed="totalsChanged" />

                        <div class="result-list">
                            <!-- <div class="blue-back">
                              <span>Je woning is energie neutraal in 2030</span>
                            </div>-->

                            <dl class="left-block">
                                <dt>Totale investering:</dt>
                                <dd>{{total | currency}}</dd>
                                <!-- <dt>Maandelijkse besparing:</dt>
                                <dd>€150</dd>
                                <dt>Invloed woningwaarde:</dt>
                                <dd class="highlight">+€30.000</dd>
                                <dt>Rendement:</dt>
                                <dd>26%</dd>
                                <dt>Terugverdiend in:</dt>
                                <dd>12 jaar</dd>
                                <dt>Uiteindelijk energielabel:</dt>
                                <dd>
                                  <span class="energy-label a"></span>
                                </dd>-->
                            </dl>

                            <div class="right-block">
                                <h5>
                                    Benieuwd hoe
                                    <br>je dit kunt financieren?
                                </h5>
                                <a disabled class="arrow-textlink">Bekijk financierings opties</a>
                            </div>
                        </div>
                    </BaseAccordion>
                </div>
                <Loader v-else />
            </div>

            <AdvicePlanDetailAside :plan="clientPlan" :client="client"></AdvicePlanDetailAside>
        </div>

        <div class="divider spacer large"></div>

        <footer class="sticky-footer border-top">
            <div class="max-width btn-row triple-btn-row">
            </div>
        </footer>
    </section>
</template>

<script>
import AdvicePlanDetailAside from "../components/AdvicePlanDetailAside.vue";
import AdvicePlanDetailTerm from "../components/AdvicePlanDetailTerm.vue";

import { mapState } from "vuex";
import { mapWaitingActions } from "vue-wait";

export default {
  name: "AdvicePlanSave",
  components: {
    AdvicePlanDetailAside,
    AdvicePlanDetailTerm
  },
  data() {
    return { allMeasures: [], totalTerm1: 0, totalTerm2: 0, totalTerm3: 0 };
  },
  methods: {
    ...mapWaitingActions("data", ["loadDataTypes"]),
    ...mapWaitingActions("advisorDashboard", [
      "loadClient",
      "loadClientPlan",
      "loadMeasures"
    ]),
    totalsChanged(term, total) {
      if (total) {
        switch (term) {
          case 0:
            this.totalTerm1 = total;
            break;
          case 1:
            this.totalTerm2 = total;
            break;
          case 2:
            this.totalTerm3 = total;
            break;
        }
      }
    }
  },
  computed: {
    ...mapState("data", ["buildingParts"]),
    ...mapState("advisorDashboard", [
      "clientPlan",
      "client",
      "proposedMeasures"
    ]),
    loaded () {
      return this.buildingParts && this.clientPlan && this.client && this.proposedMeasures;
    },
    total() {
      return this.totalTerm1 + this.totalTerm2 + this.totalTerm3;
    }
  },
  async mounted() {
    await this.loadDataTypes();
    await this.loadClient(this.$route.params.id);
    await this.loadClientPlan(this.$route.params.id);
    
    this.allMeasures = await this.loadMeasures();

      window.loadInterval = setInterval(function () {
              var output = {}
              output.consumerSituation = this.clientPlan.consumerSituation;
              output.dimensionValues = this.clientPlan.dimensionValues;
              output.totalCosts = this.total;
              output.terms = Object.keys(this.proposedMeasures).map(function (key) { //note to self: must wait for totals to load!
                  return { measures: this.proposedMeasures[key], term: key, totalCosts: this['totalTerm' + (parseInt(key) + 1)] };
              }.bind(this));

              var form = document.createElement('form');
              form.setAttribute('action', '/umbraco/surface/SavePlan/HandleSaveDefinitivePlan');
              form.setAttribute('method', 'POST');

              var input = document.createElement('input');
              input.setAttribute('type', 'hidden');
              input.setAttribute('name', 'Plan');
              input.value = JSON.stringify(output);

              var idInput = document.createElement('input');
              idInput.setAttribute('type', 'hidden');
              idInput.setAttribute('name', 'userId');
              idInput.value = this.client.id;

              form.insertBefore(input, null);
              form.insertBefore(idInput, null);
              document.body.insertBefore(form, null);

              form.submit();

              window.clearInterval(window.loadInterval);
      }.bind(this), 250)
  }
};
</script>

