<template>
  <span :class="{'icon-circle' : round, icon: !round}">
    <img :src="getMeasureIcon(measure)">
  </span>
</template>

<script type="text/javascript">
import { api } from "@/api";
export default {
  props: {
    measure: Object,
    round: Boolean,
    buildingParts: {
        type: Array,
        default: () => []
    }
  },
  methods: {
        getMeasureIcon(measure) {
          if (this.measure.iconName === undefined || this.measure.iconName === null) {
              var buildingPart = this.buildingParts.find(bp => bp.id == this.measure.buildingPartId);
              return `/assets/icons/${buildingPart.iconName}.svg`;
          }
          return `/assets/icons/${measure.iconName}.svg`;
        }
  },
};
</script>

