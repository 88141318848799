<template>
  <section class="content max-width-medium" style="border-bottom:100px solid transparent;">
    <div class="intro">
      <h6>Advies afronden</h6>
      <h3>{{term | termDisplay}}</h3>
      <p>Zie hier onder de voorgestelde maatregelen voor de {{term | termDisplay | lowerCase}}. Pas maatregelen aan of verplaats deze naar een later termijn.</p>
    </div>
    <Loader v-if="!loaded"/>
    <form action="#" class="finish finish-plan" novalidate v-if="loaded" @submit.prevent="submit()">
      <h4>
        <span class="highlight">
          <span>Investering {{term | termDisplay | lowerCase}}: {{calculateTotal() | currency}}</span>
          <!-- <span>Besparing:  €89 p/mnd</span> -->
        </span>
        Maatregelen {{term | termDisplay | lowerCase}}
      </h4>

      <!-- loop through buildingparts -->
      <div v-for="buildingPart in plan.measures" v-bind:key="buildingPart.buildingPartId">
        <div class="row-head">
          <BaseMeasureIcon :measure="buildingPart"/>
          <span class="title">{{buildingPart.buildingPartName}}</span>
          <span class="investment">
            <span>Investering: {{calculateSubTotal(buildingPart) | currency}}</span>
            <!-- <span>Besparing: €89 p/mnd</span> -->
          </span>
          <!--<span class="button term" disabled>Naar later termijn</span>
          <span class="button delete" disabled>
            <img src="/assets/icons/icon-trash-dark.svg">
          </span>-->
          <span class="tooltip-btn">
            <span class="tooltip-icon">?</span>
          </span>
        </div>
        <div
          class="row"
          v-for="dimension in buildingPart.measures"
          v-bind:key="dimension.dimensionId"
        >
          <span
            class="total right"
          >Totaal: {{dimension.amount | number}} {{dimension.dimensionUnit}}</span>
          <span class="label">{{(dimension.dimensionName.startsWith('maatregel') ? 'maatregel' : dimension.dimensionName)}}</span>

          <fancy-select
            :ref="'fancyselect:' + buildingPart.buildingPartId + ':' + dimension.dimensionId"
            :buildingPartName="buildingPart.buildingPartName"
            :name="dimension.dimensionName"
            v-model="buildingPart.selectedMeasures[dimension.dimensionId]"
            v-on:input="measureSelected(buildingPart, buildingPart.selectedMeasures[dimension.dimensionId], dimension)"
            :hasNvt="true"
            :nvtId="-1"
            :options="getOptions(buildingPart.buildingPartId)"
            :readonly="readonly"
          ></fancy-select>
          
          <div class="specs" v-if="buildingPart.selectedMeasures[dimension.dimensionId]">
            {{getTooltip(buildingPart.selectedMeasures[dimension.dimensionId])}}
            <!-- <a href="#">Meer details</a> -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- row -->

      <div class="divider"></div>

      <div class="sticky-footer light">
        <div class="btn-row max-width-medium">
          <button class="btn dark right" :disabled="$wait.any" type="submit">Verder</button>
          <a class="btn light left" @click.prevent="goBack()">Terug</a>
          <FooterNavDropdown :label="dropDownLabel"></FooterNavDropdown>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import FancySelect from "../components/FancySelect.vue";
import FooterNavDropdown from "../components/FooterNavDropdown.vue";
import { mapWaitingActions } from "vue-wait";
import { mapState } from "vuex";
import _ from "lodash";
import api from '../../../api'

export default {
  props: {
        term: Number,
        readonly: {
            type: Boolean,
            required: false,
            default: () => false
        }
  },
  components: { FancySelect, FooterNavDropdown },
  data() {
    return {
      plan: null,
      loaded: false,
      building: false,
      buildingParts: [],
      allMeasures: null
    };
  },
  computed: {
    ...mapState("advisorDashboard", ["clientPlan", "proposedMeasures"]),
    dropDownLabel() {
      return (
        "Afronden: " +
        this.$options.filters.termDisplay(this.term).toLowerCase()
      );
    }
  },
  methods: {
    ...mapWaitingActions("advisorDashboard", [
      "proposeMeasures",
      "updateAdvisePlanMeasures",
      "loadEapMeasures",
    ]),
    getOptions(buildingPartId) {
        
      return this.allMeasures.filter(m => {
        return m.buildingPartId == buildingPartId;
      });
    },

    calculateSubTotal(buildingPart) {
      return _.sumBy(buildingPart.measures, "totalCosts");
    },

    calculateTotal() {
      if (this.plan) {
        let flattened = _.flatten(_.map(this.plan.measures, "measures"));
        return _.sumBy(flattened, "totalCosts");
      }
    },

      measureSelected(buildingPart, measureId, dimension) {
        if (measureId == '' || measureId == null || measureId == -1) {
            buildingPart.measures.find(x => x.dimensionId == dimension.dimensionId).totalCosts = 0;
            buildingPart = this.plan.measures.find(x => x.buildingPartId == buildingPart.buildingPartId);
            var planDimension = buildingPart.measures.find(x => x.dimensionId == dimension.dimensionId);
            planDimension.totalCosts = 0;
            dimension.totalCosts = 0;

            let nullMeasures = JSON.parse(window.localStorage.getItem('nullMeasures'));
            if (nullMeasures == null) {
                nullMeasures = { 0: {}, 1: {}, 2: {}};
            }

            nullMeasures[this.term][buildingPart.buildingPartName] = true;
            window.localStorage.setItem('nullMeasures', JSON.stringify(nullMeasures));
            return;
        }
      let measure = _.find(this.allMeasures, { id: measureId });
      if (measure) {
        let costs =
          dimension.amount * (measure.materialCost + measure.materialVat) +
          dimension.amount * (measure.labourCost + measure.labourVat) +
          dimension.amount * measure.maintenanceCost;

        dimension.totalCosts = costs;
      }
    },

    async submit() {
      let route = {};
 
      switch (this.term) {
        case 0:
              route = {
                  name: "finish:middle", query: { readonly: this.readonly.toString()} };
          break;
        case 1:
              route = { name: "finish:long", query: { readonly: this.readonly.toString() } };
          break;
        default:
              route = { name: "details", query: { readonly: this.readonly.toString() } };
          break;
      }

      this.$router.push(route);
      },

      goBack() {
        this.$router.go(-1);
      },

    async build() {
      if (this.building) return;
      if (!this.clientPlan) return;
      if (!this.allMeasures) return;

      let nullMeasures = JSON.parse(window.localStorage.getItem('nullMeasures'));
        if (nullMeasures == null) {
            nullMeasures = {0: {}, 1: {}, 2: {}};
        window.localStorage.setItem('nullMeasures', JSON.stringify(nullMeasures));
      }

      this.building = true;

      let plan = await this.proposeMeasures();

      this.plan = _.find(plan.terms, { term: this.term });

      this.plan.measures.forEach(b => {
        b.selectedMeasures = {};
        let measures = b.measures
          .filter(m => m.proposedMeasureId > 0)
          .map(m => {
            return {
              measureId: m.proposedMeasureId,
              dimensionId: m.dimensionId
            };
          });

        let nullMeasures = JSON.parse(window.localStorage.getItem('nullMeasures'));
        if(nullMeasures == null)
            nullMeasures = { 0: {}, 1: {}, 2: {}};

        measures.forEach(m => {
          if(nullMeasures[this.term][b.buildingPartName] === true)
              b.selectedMeasures[m.dimensionId] = null;
          else
              b.selectedMeasures[m.dimensionId] = m.measureId;
        });
      });

      this.building = false;
      this.loaded = true;
    },
    getTooltip(id) {
      let measure = _.find(this.allMeasures, { id: id });
      if (measure) return measure.tooltip;

      return "";
    }
  },

  watch: {
    async clientPlan() {
      await this.build();
    },
    async allMeasures() {
      await this.build();
    }
  },
  async mounted() {

      this.allMeasures = await this.loadEapMeasures();
      await this.build();

      var userId = this.$route.params.id
      var selectedMeasures = await api.getSelectedAdvicePlanMeasures(userId, this.term);
      selectedMeasures.forEach(measure => {
          if (measure.dimensionId === 0)
              measure.dimensionId = 'null';
          var childComponents = this.$refs["fancyselect:" + measure.buildingPartId + ':' + measure.dimensionId];
          if (childComponents != null && childComponents != undefined && childComponents.constructor.name == 'Array' && childComponents.length > 0)
              childComponents.forEach((child) => child.setValue(measure.measureId));
      });
  },

    async beforeRouteLeave(to, from, next) {

        let measures = [];
        let measuresToPost = [];

        this.plan.measures.forEach(b => {

            Object.keys(b.selectedMeasures).forEach(key => {

                if (key) {

                    let measureId = b.selectedMeasures[key];

                    if (measureId == "" || measureId == null) {
                        return;
                    }

                    let measure = {
                        buildingPartId: b.buildingPartId,
                        dimensionId: key,
                        measureId,
                    };

                    measuresToPost.push(measure);

                    if (key != "null") {
                        measure.dimension = parseInt(key);

                        let dimension = b.measures.find(x => x.dimensionId == measure.dimension);
                        measure.amount = dimension.amount;
                    }
                    else {
                        measure.dimension = 0;
                        measure.amount = 1;
                    }

                    measures.push(measure);
                }
            });
        });

        var userId = this.$route.params.id;
        api.setSelectedAdvicePlanMeasures(userId, this.term, measuresToPost);

        await this.updateAdvisePlanMeasures({
            term: this.term,
            measures
        });
        
        next();
    },
};
</script>

