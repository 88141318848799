<template>
    <input type="hidden" name="Plan" :value="plan | json" />
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "HiddenPlanInput",
    computed: {
        ...mapState('provisionalPlan', ['plan'])
    }
}
</script>

