<template>
    <div class="file-uploader-component">

        <h3>{{title}}</h3>

        <div class="showcase">
            <ul>
                <FileUploaderFile v-for="(file, index) in filesArray" :file="file" :key="file.id" :showButton="showButton" :imageExtensions="imageExtensions" @showLightbox="showLightbox" @delete-file="deleteFile" />
            </ul>
        </div>

        <div class="btn-row" style="display: flex;" v-if="showButton">
            <input :accept="accept" class="inputfile" type="file" id="files" @change="handleFileUpload()" ref="files" data-multiple-caption="{count} files selected" multiple />
            <label class="file-counter btn dark" ref="fileCounter" for="files">Bestand Kiezen</label>
            
            <button @click="submitFile('', '')"
                    :class="['btn', 'light', 'upload', { 'upload--loading': uploading }]"
                    v-if="fileCounter"
                    >
                Opslaan
            </button>

        </div>

        <Lightbox :visible.sync="lightboxVisible" :images="lightboxImages" :current.sync="lightboxCurrent" />

    </div>
</template>

<script>
    import { mapState } from "vuex";
    import axios from 'axios';
    import api from "../api";
    import Lightbox from "./Lightbox";
    import FileUploaderFile from "./FileUploaderFile";

    export default {
        props: {
            title: String,
            showButton: {
                type: Boolean,
                required: false,
                default: false
            },
            filesArray: Array,
            uploading: {
                type: Boolean,
                default: false,
            },
            accept: {
                type: String,
                default: null,
            }
        },
        data: () => {
            return {
                fileCounter: Number,
                lightboxVisible: false,
                lightboxImages: [],
                lightboxCurrent: 0,
                imageExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
            }
        },
        components: {
            Lightbox,
            FileUploaderFile,
        },
        watch: {
            fileCounter: function () {
                var counter = this.$refs['files'].files;
                var buttontxt = this.$refs['fileCounter'];

                if (counter.length == 0) {
                    buttontxt.innerHTML = "Bestand Kiezen";
                }
                else if (counter.length == 1) {
                    buttontxt.innerHTML = counter[0].name;
                }
                else if (counter.length > 1) {
                    buttontxt.innerHTML = counter.length + " Bestanden";
                }
            },

            filesArray(filesArray) {

                var lightboxArray = [],
                    i = 0;

                for (var index in filesArray) {

                    var file = filesArray[index];

                    if (!this.isImage(file.extension)) {
                        continue;
                    }

                    this.filesArray[index].lightboxIndex = i;

                    lightboxArray[i] = {
                        src: file.url,
                    };

                    i++;
                }
                
                this.lightboxImages = lightboxArray;
            },
        },
        computed: {
            ...mapState("core", ["user"]),
            itemsToShow() {
                if (this.items == null) {
                    return [];
                }
                else {
                    return [];
                }
            },
        },
        methods: {

            showLightbox(current) {
                this.lightboxCurrent = current;
                this.lightboxVisible = true;
            },

            submitFile(description, subject) {
                let formData = new FormData();
                for (var i = 0; i < this.files.length; i++) {
                    let file = this.files[i];
                    formData.append('files[' + i + ']', file);
                }

                this.$emit('submit-files', formData, description, subject);

                this.$refs['files'].value = '';
                this.handleFileUpload();
            },

            handleFileUpload() {
                this.files = this.$refs['files'].files;

                this.fileCounter = this.$refs['files'].files.length;
            },

            isImage(fileExtension) {

                return this.imageExtensions.includes(fileExtension.toLowerCase());
            },

            deleteFile(id) {
                this.$emit('delete-file', id);
            }
        },
        async mounted() {

            if (this.showButton) {

                this.fileCounter = this.$refs['files'].files.length;
            }
        }
    };
</script>

<style lang="scss" scoped>
    /*************************************************
     * COMPONENT CSS STAAT IN ASSETS/SCSS/COMPONENTS *
     *************************************************/
</style>