import api from '../../api'

const state = {
  openBankTransfers: null,
  openIdealTransfers: null
};

const getters = {

    openBankTransfers(state) {

        return state.openBankTransfers;
    },

    openIdealTransfers(state) {
      return state.openIdealTransfers;
    }
};

const actions = {

    async loadOpenBankTransfers({ commit }) {

        await api.getOpenBankTransfers().then((openBankTransfers) => {

            commit('openBankTransfers', openBankTransfers);
        });
    },

    async setBankTransferReceived({ commit }, userId) {

        let data = await api.setBankTransferReceived(userId);
        
        if (data.success) {

            commit('removeOpenBankTransfer', userId);
        }

        return data;
  },

    async loadOpenIdealTransfers({ commit }) {
      await api.getOpenIdealTransfers().then((openIdealTransfers) => {
        commit('openIdealTransfers', openIdealTransfers);
      });
  },

  async setIdealTransferReceived({ commit }, userId) {

      let data = await api.setIdealTransferReceived(userId);
       if (data.success)
        commit('removeOpenIdealTransfer');

      return data;
    }

};

const mutations = {

    openBankTransfers(state, openBankTransfers) {

        state.openBankTransfers = openBankTransfers;
    },

    removeOpenBankTransfer(state, userId) {

        const index = state.openBankTransfers.findIndex(openBankTransfer => openBankTransfer.userId == userId);

        state.openBankTransfers.splice(index, 1);
    },

    openIdealTransfers(state, openIdealTransfers) {
        state.openIdealTransfers = openIdealTransfers;
    },

  removeOpenIdealTransfer(state, userId) {

      const index = state.openIdealTransfers.findIndex(openIdealTransfer => openIdealTransfer.userId == userId);

      state.openIdealTransfers.splice(index, 1);
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};