<template>
  <a class="fancy-select" @click.prevent="toggleCompletePlanNavOverlay()">
    <span class="select-text">
      {{label}}
      <i></i>
    </span>
  </a>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    label: String
  },
  methods: {
    ...mapActions("dashboard", ["toggleCompletePlanNavOverlay"])
  }
};
</script>
