import api from '../../api';
import { getField, updateField } from 'vuex-map-fields';
import { remove, filter } from 'lodash';

const state = {
	buildingParts: []
};

const getters = {
	getField
};

const actions = {

	async loadData({ dispatch, commit }) {

		var currentPlanResponse = await api.loadCurrentPlan();
    var currentPlan = JSON.parse(currentPlanResponse.plan);

		commit('consumer/updateField', { path: 'monthlyDeposit', value: currentPlan.consumerSituation.monthlyDeposit }, { root: true });
		commit('consumer/updateField', { path: 'currentPlan', value: currentPlan }, { root: true });
    dispatch('_loadStep1Data', currentPlan);
		dispatch('_loadStep2Data', currentPlan);
		dispatch('_loadStep3Data', currentPlan);
		dispatch('_loadStep4Data', currentPlan);
	},

	_loadStep1Data({ commit }, currentPlan) {
		commit('consumer/updateField', { path: 'ownershipType', value: 0 }, { root: true });
		commit('consumer/updateField', { path: 'monument', value: 0 }, { root: true });
		commit('consumer/updateField', { path: 'areaOfUse', value: currentPlan.consumerSituation.areaOfUse }, { root: true });
		commit('consumer/updateField', { path: 'buildYear', value: currentPlan.consumerSituation.buildYear }, { root: true });
		commit('consumer/updateField', { path: 'buildingType', value: currentPlan.consumerSituation.buildingType }, { root: true });
	},

	_loadStep2Data({ commit }, currentPlan) {
		var measuresObject = {};
		for (var measure of currentPlan.consumerSituation.measures) {

			measuresObject[measure.key] = measure.value;
		}

		commit('consumer/updateField', { path: 'measures', value: measuresObject }, { root: true });
	},

	_loadStep3Data({ commit }, currentPlan) {
		commit('consumer/updateField', { path: 'numberOfAdults', value: currentPlan.consumerSituation.numberOfAdults }, { root: true });
		commit('consumer/updateField', { path: 'stageOfLife', value: currentPlan.consumerSituation.stageOfLife }, { root: true });
		commit('consumer/updateField', { path: 'numberOfChildren', value: currentPlan.consumerSituation.numberOfChildren }, { root: true });
	},

	_loadStep4Data({ commit }, currentPlan) {
		commit('consumer/updateField', { path: 'reason', value: currentPlan.consumerSituation.reason }, { root: true });
		commit('consumer/updateField', { path: 'ambition', value: currentPlan.consumerSituation.ambition }, { root: true });
	},

	async loadStep2Data({ commit, rootGetters }) {
		let measures = await api.loadQuickScanMeasures();
		let parts = await api.loadBuildingParts(rootGetters['data/userPlanOrderName']);

		// we only need the QuickScanVisible parts
		parts = filter(parts, { quickScanVisible: true });
		parts.forEach((part) => {
			part.measures = filter(measures, { buildingPartId: part.id });
		});


		// remove any parts without a measure
		remove(parts, (p) => {
			return !p.measures.length;
		});
		commit('updateField', { path: 'buildingParts', value: parts });
	},

	clearData({ commit }) {
		commit('consumer/updateField', { path: 'ownershipType', value: null }, { root: true });
		commit('consumer/updateField', { path: 'monument', value: null }, { root: true });
		commit('consumer/updateField', { path: 'areaOfUse', value: null }, { root: true });
		commit('consumer/updateField', { path: 'buildYear', value: null }, { root: true });
		commit('consumer/updateField', { path: 'buildingType', value: null }, { root: true });
		commit('consumer/updateField', { path: 'measures', value: null }, { root: true });
		commit('consumer/updateField', { path: 'numberOfAdults', value: null }, { root: true });
		commit('consumer/updateField', { path: 'stageOfLife', value: null }, { root: true });
		commit('consumer/updateField', { path: 'numberOfChildren', value: null }, { root: true });
		commit('consumer/updateField', { path: 'reason', value: null }, { root: true });
		commit('consumer/updateField', { path: 'ambition', value: null }, { root: true });
	}
};

const mutations = {
	updateField
};

export default {
	namespaced: true,
	getters,
	state,
	actions,
	mutations
};
