import GasConstants from "./GasConstants";
import axios from 'axios';

export default {

    _getGasUsage: function(deposit) {
        var gasShare = parseFloat((deposit * GasConstants.DEPOSIT_GAS_SHARE).toFixed(2));
        var gasCostsWithoutFixedRate = parseFloat((gasShare - GasConstants.GAS_FIXED_CHARGE).toFixed(2));
        var gasUsageInM3 = parseFloat((gasCostsWithoutFixedRate / GasConstants.GAS_PRICE_PER_M3).toFixed(2));
        return gasUsageInM3;
    },

    _getGasSavings: async function (deposit, measures) {
        //gas savings from isolation
        var gasUsage = this._getGasUsage(deposit);
        var ids = measures.map((x) => x.id);
        var savingsResponse = await axios.post('/api/v1/measures/getSavingsIndicator', ids);
        var correctionResponse = await axios.post('api/v1/measures/getCorrectionFactor', ids);
        let savingsIndicator = savingsResponse.data.SavingsIndicator;
        let correctionFactor = correctionResponse.data.CorrectionFactor;
        let finalFactor = parseFloat((savingsIndicator * correctionFactor).toFixed(3));
        let newUsage = parseFloat((gasUsage * finalFactor).toFixed(2));
        let savedGas = parseFloat((gasUsage - newUsage).toFixed(2));
        let totalSavings = parseFloat((Math.floor(parseFloat((savedGas * GasConstants.GAS_PRICE_PER_M3).toFixed(2)) * 100) / 100).toFixed(2));

        //gas savings from heating
        let heatingSavingsResponse = await axios.post('api/v1/measures/getHeatingSavings', ids);
        let heatingSavingsIndicator = heatingSavingsResponse.data.SavingsIndicator;
        let heatingSavingsM3 = parseFloat((newUsage * heatingSavingsIndicator).toFixed(2));
        totalSavings = parseFloat((totalSavings + parseFloat((heatingSavingsM3 * GasConstants.GAS_PRICE_PER_M3).toFixed(2))).toFixed(2));

        //if (measures.some((x) => x.name.toLowerCase().indexOf('warmtepomp') > 0)) { //if warmtepomp is going to be added, fixed gas charge is abolished.
        //    totalSavings = parseFloat((totalSavings + GasConstants.GAS_FIXED_CHARGE).toFixed(2));
        //}

        return totalSavings;
    },

    _getElectricitySavings: async function (measures) {
        let ids = measures.map((x) => x.id);
        let apiResponse = await axios.post('api/v1/measures/getElectricityGeneration', ids);
        let electricityGeneration = apiResponse.data.NetGeneration;
        let electricityPriceKwh = 0.2269;
        let electricitySavingsYearly = parseFloat((electricityGeneration * electricityPriceKwh).toFixed(2));
        let electricitySavingsMonthly = parseFloat((electricitySavingsYearly / 12).toFixed(2));
        return electricitySavingsMonthly;
    },

    CalculateSavings: async function (deposit, term) {
        var gasSavings = await this._getGasSavings(deposit, term.measures);
        var electricitySavings = await this._getElectricitySavings(term.measures);
        var totalSavings = parseFloat((gasSavings + electricitySavings).toFixed(2));
        return totalSavings;
    }
}