<template>
  <div>
    <Sidenav />
    <section class="steps light subheader" v-if="$route.fullPath.substr(-8) != '/opslaan'">
      <div class="max-width-medium">
        <ul class="right">
          <li class="user">
            <avatar-icon v-if="client" :first-name="client.firstName" :hash="client.avatarHash"/>
            <!-- <name>{{firstName}} {{lastName}}</name> -->
          </li>
          <li v-for="step in steps" :key="step.number" :class="getClass(step)">
            <router-link tag="a" :to="step.route">
              <span class="nr">{{step.number}}</span>
              <span class="label">{{step.name}}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>

    <CompletePlanSideNav :readonly="readonly"></CompletePlanSideNav>
    <router-view v-if="client" :key="$route.fullPath"/>
  </div>
</template>
<script>
import { mapWaitingActions } from "vue-wait";
import { mapState } from "vuex";
import AvatarIcon from "~/src/components/AvatarIcon.vue";
import CompletePlanSideNav from "../components/CompletePlanSideNav.vue";
import Sidenav from '../components/Sidenav.vue';

export default {
  data() {
    return {
      steps: [
        {
          route: { name: "advise-plan-step1", query: { readonly: this.readonly.toString() } },
          number: 1,
          name: "Basisgegevens",
        },
        {
          route: { name: "advise-plan-step2", query: { readonly: this.readonly.toString() } },
          number: 2,
          name: "Isolatie"
        },
        {
          route: { name: "Installatie", query: { readonly: this.readonly.toString() } },
          number: 3,
          name: "Installatie"
        },
        {
          route: { name: "Overige", query: { readonly: this.readonly.toString() } },
          number: 4,
          name: "Overige"
        },
        {
          route: { name: "finish:short", query: { readonly: this.readonly.toString() } },
          number: 5,
          name: "Afronden"
        }
      ]
    };
        },
  props: {
      readonly: {
          type: Boolean,
          required: false,
          default: () => false
      }
  },
  components: {
    AvatarIcon,
    CompletePlanSideNav,
    Sidenav
  },
  methods: {
    ...mapWaitingActions("advisorDashboard", {
      loadClientPlan: "loading plan",
      loadClient: "loading client",
      clearFileStorage: "loading files",
      loadAdvisorPlanFilesByUserId: "loading files",
    }),
    getClass(r) {
      let classes = [];
      if (r.route && r.route.name == this.$route.name)
        classes.push("current active");

      if (this.$route.name.indexOf(":" + r.name) !== -1) {
        classes.push("current active");
      }
      if (this.$route.name.startsWith(r.route.name))
        classes.push("current active");
      return classes;
    }
  },
  computed: {
    ...mapState("advisorDashboard", ["client"])
  },
  async mounted() {
    await this.loadClient(this.$route.params.id);
    await this.loadClientPlan(this.$route.params.id);
    await this.clearFileStorage();
    await this.loadAdvisorPlanFilesByUserId(this.client.id);
  },
  created() {
    document.querySelector("body").classList.add("portal");
  },

    beforeRouteLeave(to, from, next) {

        document.querySelector("body").classList.remove("portal");    

        next();
    },
};
</script>
