<template>
  <div :class="['overlay', { 'open': completePlanNavOverlayOpen }]">
    <div class="overlay-slide nav-slide">
      <a @click.prevent="toggleCompletePlanNavOverlay()" class="close"></a>

      <div class="nav-content steps">
        <h4>Plan definitief maken</h4>

        <ul>
          <li v-for="r in routes" :class="getClass(r)" :key="r.to.name">
            <span class="nr">{{r.number}}</span>
            <a @click.prevent="goToRoute(r)">{{r.display}}</a>

            <ul v-if="r.children.length">
              <li v-for="subNode in r.children" :class="getClass(subNode)" :key="subNode.to.name">
                <a @click.prevent="goToRoute(subNode)">{{subNode.display}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from "../step2-routes.js";
import { mapState, mapActions } from "vuex";
import { some, uniqBy, filter } from "lodash";

export default {
  props: {
        readonly: {
            type: Boolean,
            required: false,
            default: () => false
    }
  },
  computed: {
    ...mapState("dashboard", ["completePlanNavOverlayOpen"]),
    routes() {
        let tree = [];
        const categories = uniqBy(routes, "category");

      let number = 1;
      tree.push({
        number,
        display: "Basisgegevens",
        to: {
          name: "advise-plan-step1"
        },
        children: []
      });

      categories.forEach(c => {
        number++;

          let groups = filter(routes, r => r.category == c.category);

        let node = {
          number: number,
            display: c.category,
          to: { name: c.name },
          children: []
        };

          if (c.props({ query: '' }).groupName) {
              node.display = `${c.props({ query: '' }).category} per bouwdeel:`;

          groups.forEach(g => {
            let subNode = {
                display: g.props({ query: '' }).groupName,
              to: {
                  name: `${g.props({ query: '' }).groupName}:${g.props({ query: '' }).category}`
              }
            };

            node.children.push(subNode);
          });
        }

        tree.push(node);
      });

      tree.push({
        number: ++number,
        display: "Afronden",
        children: [
          {
            display: "Korte termijn",
            to: {
              name: "finish:short"
            }
          },
          {
            display: "Middellang termijn",
            to: {
              name: "finish:middle"
            }
          },
          {
            display: "Lange termijn",
            to: {
              name: "finish:long"
            }
          }
        ],
        to: {
          name: "finish:short"
        }
      });

      return tree;
    }
  },
  created() {},
  methods: {
    ...mapActions("dashboard", ["toggleCompletePlanNavOverlay"]),
    
    getClass(r) {
      let classes = [];
      if (r.to && r.to.name == this.$route.name) classes.push("current active");

      if (r.children) {
        let anyActive = some(r.children, s => {
          return s.to && s.to.name == this.$route.name;
        });
        if (anyActive) classes.push("current");
      }

      return classes;
    },
    goToRoute(r) {
        this.$router.push({ name: r.to.name, query: { readonly: this.readonly.toString() } }, () => {
        this.toggleCompletePlanNavOverlay();
      });
    }
  }
};
</script>

