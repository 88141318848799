<template>
  <sidenav v-if="sideNavOpen" style="z-index: 26">
    <a class="close" @click.prevent="toggleSideNav()"></a>
    <div class="logo">
      <a href="/">Energiek Zeeland</a>
    </div>
    <div class="side-nav" v-html="html"></div>
  </sidenav>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("ui", ["sideNavOpen"])
  },
  methods: {
    ...mapActions("ui", ["toggleSideNav"])
  },
  data() {
    return {
      html: null
    };
  },
  mounted() {
    let content = document.getElementById("sidenav-content");
    if(content) {
      let html = content.innerHTML;
      this.html = html;
    }
  }
};
</script>