<template>
    <button :disabled="isLoading" :class="classes" @click="clicked">

        <transition name="grow">

            <span class="base-button-component__icon-container" v-if="status != null">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="animation--rotate" v-if="isLoading">
                    <path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-if="isSuccess">
                    <path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"></path>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-if="isFailed">
                    <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014 11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328 5.745-11.985 12.599z"></path>
                </svg>

            </span>

        </transition>

        <slot></slot>

    </button>
</template>

<script>
    const STATUS_LOADING = 'loading',
          STATUS_SUCCESS = 'success',
          STATUS_FAILED  = 'failed';

    export default {

        props: {
            classString: {
                type: String,
                default: null,
            },
            status: {
                type: String,
                default: null,
                validator: (value) => [STATUS_LOADING, STATUS_SUCCESS, STATUS_FAILED, null].includes(value),
            },
            to: {
                type: Object,
            },
        },
        
        computed: {

            isLoading() {
                return this.status == STATUS_LOADING;
            },

            isSuccess() {
                return this.status == STATUS_SUCCESS;
            },

            isFailed() {
                return this.status == STATUS_FAILED;
            },

            classes() {
                return {
                    'base-button-component': true,
                    ['base-button-component--' + this.status]: this.status,
                    [this.class]: this.class,
                };
            },
        },

        methods: {

            clicked() {

                this.$emit('click');

                if (this.to) {

                    this.$router.push(this.to);
                }
            },
        },
    };
</script>