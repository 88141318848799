import api from '~/src/api';

const state = {
	plan: null,
};

const getters = {

	plan(state) {

		return state.plan;
	},
};

const actions = {

	async load({ commit }) {

		await api.loadAdvicePlan().then(plan => {

			var planData = JSON.parse(plan.plan);

			commit('plan', planData);
		});
	},
};

const mutations = {

	plan(state, plan) {

		state.plan = plan;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};