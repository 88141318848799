<template>
  <div>
    <button class="accordion-link">
      <!-- <span class="item-savings">Besparing: €125 per/mnd</span> -->
      <i></i>
      Fase {{term + 1}}: {{term | termDisplay}}
      <span
        class="price"
      >{{calculateTotal() | currency}}</span>
    </button>
    <div class="panel">
      <div class="product-item" v-for="(buildingPart, index) in model" v-bind:key="buildingPart.id" v-if="buildingPart.measures && buildingPart.measures.length > 0">
        <ProductItem :building-part="buildingPart" :index="index+1"/>
      </div>
      <!-- product-item -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { map, groupBy, isArray, pick, merge, sumBy, flatten } from "lodash";
import ProductItem from "./AdvicePlanDetailTermItem.vue";

export default {
  props: {
    term: Number,
    measures: Array,
    allMeasures: Array,
    totalCostsChanged: {
      type: Function,
      default() {
        return () => {};
      }
    }
  },
  components: {
    ProductItem
  },
  // propss: ["term", "measures", "allMeasures", "totalCostsChanged"],
  data() {
    return {
      model: {}
    };
  },
  methods: {
    calculateTotal() {
      let total = sumBy(
        flatten(map(this.model, m => m.measures)),
        "totalCosts"
      );
      this.totalCostsChanged(this.term, total);
      return total;
    },
    transform(measures) {
      if (!this.allMeasures || !this.buildingParts || !measures) return;

      if (!isArray(measures)) return;

      let grouped = groupBy(measures, "buildingPartId");
      this.model = [];

      Object.keys(grouped).forEach(buildingPartId => {
        let buildingPart = this.buildingParts.find(x => x.id == buildingPartId);

        if (buildingPart) {
          let subModel = {
            ...pick(buildingPart, ["id", "name", "iconName"]),
            measures: []
          };

          let group = grouped[buildingPartId];
            group.forEach(selectedMeasure => {
            let measure = null;
            if (selectedMeasure.measureId == -1)
                measure = {
                    id: -1,
                    name: 'n.v.t',
                    maintenanceCost: 0,
                    labourCost: 0,
                    labourVat: 0,
                    materialCost: 0,
                    materialVat: 0,
                    tooltip: 'n.v.t',
                    description: 'n.v.t'
                };
            else {
                measure = pick(
                    this.allMeasures.find(m => m.id == selectedMeasure.measureId),
                    [
                        "id",
                        "name",
                        "maintenanceCost",
                        "labourCost",
                        "labourVat",
                        "materialCost",
                        "materialVat",
                        "tooltip",
                        "description"
                    ]
                );            
            }

            
            measure.measureId = measure.id;

            if (selectedMeasure.dimension) {
              let dimension = this.buildingPartDimensions.find(
                x => x.id == selectedMeasure.dimension
              );
              merge(measure, pick(dimension, ["id", "groupName", "unit"]));
            }
            measure.amount = selectedMeasure.amount;
            measure.totalCosts =
              measure.amount * measure.maintenanceCost +
              measure.amount * ( measure.labourCost + measure.labourVat) +
              measure.amount * ( measure.materialCost + measure.materialVat);

            subModel.measures.push(measure);
          });

          this.model.push(subModel);
        }
      });
    }
  },
  computed: {
    ...mapState("data", ["buildingParts", "buildingPartDimensions"])
  },
  watch: {
    measures: {
      immediate: true,
      handler() {
        this.transform(this.measures);
      }
    },
    allMeasures: {
      immediate: true,
      handler() {
        this.transform(this.measures);
      }
    },
    buildingParts: {
      immediate: true,
      handler() {
        this.transform(this.measures);
      }
    }
  }
};
</script>

