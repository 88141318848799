import api from '~/src/api';
import _ from 'lodash';

const state = {
	plan: null,
	rawPlan: null,
};

const getters = {

	plan(state) {

		return state.plan;
    },
};

const actions = {

	async create({ state, dispatch, rootState }) {
		
		if (state.plan)
			return;
		
		if (!rootState.advicePlan.plan)
			await dispatch('advicePlan/load', null, {root: true});
		
		dispatch('setPlan', rootState.advicePlan.plan);
	},

	async setPlan({ commit }, plan) {

		commit('rawPlan', plan);

		var measureData = await api.loadAdvisorMeasures();
		
		plan.terms = plan.terms.map(planTerm => {

			var uniqueMeasures = planTerm.measures.filter((_measure, index, self) => self.map(_measure => _measure.measureId).indexOf(_measure.measureId) === index && _measure.measureId != -1);

			planTerm.measures = uniqueMeasures.map(_uniqueMeasure => {
				_uniqueMeasure = {
					..._uniqueMeasure,
					...measureData.find(dataMeasure => dataMeasure.id === _uniqueMeasure.measureId),
				};

				var duplicateMeasures = planTerm.measures.filter(_measure => _measure.measureId === _uniqueMeasure.measureId);
				_uniqueMeasure.amount = duplicateMeasures.reduce((accumulator, _measure) => accumulator + _measure.amount, 0);
				_uniqueMeasure.totalCosts = _uniqueMeasure.amount * (_uniqueMeasure.maintenanceCost + _uniqueMeasure.labourCost + _uniqueMeasure.labourVat + _uniqueMeasure.materialCost + _uniqueMeasure.materialVat);
				
				return _uniqueMeasure;
			});

			planTerm.measures = planTerm.measures.filter(_measure => !_measure.visibleForConsumer);

			planTerm.totalCosts = Math.round(planTerm.measures.reduce((total, measure) => total += measure.totalCosts, 0));

			return planTerm;
		});
		
		commit('plan', plan);
	},

	moveMeasure({ dispatch }, payload) {

		dispatch('quotation/resetChosenPartners', null, { root: true });
		dispatch('_moveMeasure', Object.assign({ plan: 'plan' }, payload));
		dispatch('_moveMeasure', Object.assign({ plan: 'rawPlan' }, payload));
	},

	_moveMeasure({ commit }, { plan, term, measure, direction }) {
		
		var planData = _.cloneDeep(state[plan]);
		
		var movedMeasure = planData.terms[term].measures.splice(measure, 1)[0];
		planData.terms[term + direction].measures.push(movedMeasure);

		planData.terms[term].totalCosts -= movedMeasure.totalCosts;
		planData.terms[term + direction].totalCosts += movedMeasure.totalCosts;

		commit(plan, planData);
	},
};

const mutations = {

	plan(state, plan) {

		state.plan = plan;
	},

	rawPlan(state, rawPlan) {

		state.rawPlan = rawPlan;
    }
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};