import api from '../../api';
import { getField, updateField } from 'vuex-map-fields';

const state = {
	allBuildingTypes: null,
	buildingTypes: null,
	lifeStages: null,
	buildYears: null,
	energyEfficiencies: null,
	buildingParts: [],
	buildingPartCategories: [],
	buildingPartDimensions: []
};

const getters = {
	getField,

	termPlanOrderName() {
		return 'TermPlanOrder';
	},

	userPlanOrderName() {
		return 'UserPlanOrder';
	},

	installationPlanOrderName() {
		return 'InstallationPlanOrder';
	},

	insulationPlanOrderName() {
		return 'InsulationPlanOrder';
	},
};

const actions = {
	async loadDataTypes({ commit }, buildingPartOrder = null) {
		let parts = await api.loadBuildingParts(buildingPartOrder);
		let categories = await api.loadBuildingPartCategories();
		let dimensions = await api.loadDimensions();
		let { buildingTypes, buildYears, lifeStages, energyEfficiencies } = await api.loadQuickScanDataTypes();

		commit('setBuildingParts', parts);
		commit('setBuildingPartCategories', categories);
		commit('setBuildingPartDimensions', dimensions);
		commit('updateField', { path: 'allBuildingParts', value: parts });
		commit('updateField', { path: 'buildingTypes', value: buildingTypes });
		commit('updateField', { path: 'buildYears', value: buildYears });
		commit('updateField', { path: 'lifeStages', value: lifeStages });
		commit('updateField', { path: 'energyEfficiencies', value: energyEfficiencies });
	}
};

const mutations = {
	updateField,
	setBuildingParts(state, parts) {
		state.buildingParts = parts;
	},
	setBuildingPartCategories(state, parts) {
		state.buildingPartCategories = parts;
	},
	setBuildingPartDimensions(state, parts) {
		state.buildingPartDimensions = parts;
	}
};

export default {
	namespaced: true,
	getters,
	state,
	actions,
	mutations
};
