<template>
    <textarea :name="name" :placeholder="placeholder" class="base-textarea-component" v-model="value"></textarea>
</template>

<script>
    export default {

        props: {
            placeholder: {
                type: String,
                required: false,
                default: '',
            },
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                value: this.getSlotValue()
            }
        },

        methods: {

            getSlotValue() {

                if (!this.$slots.default || !this.$slots.default.length) {

                    return '';
                }

                return this.$slots.default[0].text;
            },
        },
    }
</script>
