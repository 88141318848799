<template>
  <div class="product-highlight">
    <BaseMeasureIcon :measure="buildingPart"/>

    <div class="item-specs">
      <div class="item-head">Stap {{index}}: {{buildingPart.name}}</div>
      <div class="item-savings">
        <span>Totale investering: {{calculateSubTotal(buildingPart) | currency}}</span>
      </div>
    </div>
    <div class="fold-button" @click="toggle()" :class="{'open': isOpen}"></div>
    <ul class="item-list" v-if="isOpen">
      <li v-for="measure in buildingPart.measures" v-bind:key="measure.id">
        <span class="item-part" v-if="measure.groupName">{{measure.groupName}}</span>
        <span class="item-name">{{measure.name}}</span>
        <span class="more-info" @click="showMeasureOverlay(measure)">Meer info</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { sumBy } from "lodash";
import { mapActions } from "vuex";


export default {
  data() {
    return { isOpen: false };
  },
  props: {
    buildingPart: Object,
    index: Number
  },
  methods: {
    ...mapActions("ui", ["openOverlay"]),
    calculateSubTotal(buildingPart) {
      return sumBy(buildingPart.measures, "totalCosts");
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    showMeasureOverlay(measure) {
      this.openOverlay({
        content: measure,
        componentName: "CustomMeasureOverlay"
      });
    }
  }
};
</script>
