<template>
    <div :class="['overlay', { 'open': overlayOpen }]">
        <div class="overlay-slide item-slide">
            <a @click="closeOverlay()" class="close"></a>
            <component :is="overlayComponent" :content="overlayContent" v-if="overlayComponent"></component>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {

        name: 'BaseOverlay',

        computed: {

            ...mapState("ui", [
                "overlayContent",
                "overlayComponentName",
                "overlayOpen",
            ]),

            overlayComponent() {

                if (!this.overlayComponentName)
                    return null;

                return () => import(`@/components/overlays/${this.overlayComponentName}`);
            },
        },
        methods: {

            ...mapActions("ui", [
                "closeOverlay",
            ]),
        }
    };
</script>
