<template>
  <div class="slide-in-nav advisor" :class="{'open':sideNavOpen}">
    <div class="close">
      <a @click.prevent="closeSideNav()">×</a>
    </div>

    <div class="logo">
      <a href="/">Energiek Zeeland</a>
    </div>

    <div class="my-advisor">
      <figure>

          <Avatar :img="currentUser.avatar" :size=70 v-if="currentUser != null && currentUser.avatar != null && currentUser.avatar.trim().length > 0" />
        
        <span v-else-if="currentUser != null"><avatar-icon :hash="currentUser.avatarHash" :first-name="currentUser.firstName"></avatar-icon></span>
      </figure>
      <div class="my-advisor-specs">
        <name v-if="currentUser != null">{{ currentUser.fullName }}</name>
        <!--<div></div> regio naam moet hier -->
      </div>
    </div>

    <nav class="agent-nav">
        <ul v-if="!externalMode">
            <li v-bind:class="{active: this.$route.name == 'home' }">
                <router-link tag="a" :to="{name : 'home'}">Dashboard</router-link>
            </li>
            <li v-bind:class="{active: this.$route.name == 'clientoverview'}">
                <router-link tag="a" :to="{name : 'clientoverview'}">Klanten</router-link>
            </li>
            <li v-bind:class="{active: this.$route.name == 'appointmentpage'}">
                <router-link tag="a" :to="{name : 'appointmentpage'}">Agenda</router-link>
            </li>
        </ul>
        <ul v-if="externalMode">
            <li>
                <a href="/account/#/">Dashboard</a>
            </li>
            <li>
                <a href="/account/#/klanten">Klanten</a>
            </li>
            <li>
                <a href="/account/#/agenda">Agenda</a>
            </li>
        </ul>
    </nav>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Avatar from "../../../components/Avatar";
import AvatarIcon from "../../../components/AvatarIcon.vue";
import api from "../../../api/index.js";

export default {
  components: {
      Avatar,
    AvatarIcon
  },
  props: {
      externalMode: {
          type: Boolean,
          default: false
      }
  },
  data: () => {
      return {
          currentUser: null
      }
  },
  computed: {
      ...mapState("ui", ["sideNavOpen"])
  },
  async mounted() {
    let hamburger = document.querySelector(".hamburger");
    hamburger.style.display = "block";

      if (hamburger.getAttribute('data-wired') != 'true') {
          hamburger.addEventListener("click", () => {
              this.$store.dispatch("ui/toggleSideNav");
          });
          hamburger.setAttribute('data-wired', 'true')
      }

      this.currentUser = await api.loadCurrentUser();
  },
  methods: {
    closeSideNav() {
      this.$store.dispatch("ui/toggleSideNav");
    }
  }
};
</script>