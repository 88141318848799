import Vue from 'vue';
import api from '~/src/api';

const state = {
	realisationPartnerCategories: null,
	chosenPartners: {},
};

const getters = {

	realisationPartnerCategories(state) {

		if (!state.realisationPartnerCategories)
			return [];

		return state.realisationPartnerCategories;
	},

	chosenPartners(state) {

		return state.chosenPartners;
    },
};

const actions = {

	async loadRealisationPartnerCategories({ commit }, advicePlan) {

		await api.getRealisationPartnerCategories(advicePlan).then((realisationPartnerCategories) => {

			commit('realisationPartnerCategories', realisationPartnerCategories);
		});
	},

	async saveRealizationPartners({ state }) {

		return api.setRealizationPartners(Object.values(state.chosenPartners));
    },

	setChosenPartner({ commit, state }, { category, companyId }) {

		var categoryIndex = state.realisationPartnerCategories.findIndex(realisationPartnerCategory => realisationPartnerCategory.category == category);

		if (categoryIndex === -1)
			return;

		Vue.set(
			state.chosenPartners,
			categoryIndex,
			{
				category: category,
				CompanyId: companyId,
			}
		);

		commit('chosenPartners', state.chosenPartners);
	},

	resetChosenPartners({ commit }) {

		commit('chosenPartners', {});
    },
};

const mutations = {

	realisationPartnerCategories(state, realisationPartnerCategories) {

		state.realisationPartnerCategories = realisationPartnerCategories;
	},

	chosenPartners(state, chosenPartners) {

		state.chosenPartners = chosenPartners;
    },
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};