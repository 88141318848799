<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields: mapDataFields } = createHelpers({
  getterType: "data/getField",
  mutationType: "data/updateField"
});

export default {
  computed: {
    ...mapDataFields(["buildingTypes", "buildYears", "lifeStages"])
  }
};
</script>
