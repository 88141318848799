
<template>
    <div v-if="schema != null && dimensions != null">
        <div v-for="buildingPart in filteredBuildingParts" :key="buildingPart.id">
            <h4 v-bind:data-building-part="buildingPart.name">{{buildingPart.name}}</h4>
            <div class="row">
                <span class="label">Bestaande situatie</span>

                <div v-if="buildingPart.name == 'Verwarming'" v-on:change="heatingValue">
                    <fancy-select :name="buildingPart.id"
                                  @input="setMeasure(buildingPart.id, groupName, arguments[0])"
                                  v-validate="'required'"
                                  :options="buildingPart.measures"
                                  :nvtId="-1"
                                  :hasNvt="true"
                                  :ref="'fancyselect' + buildingPart.id"
                                  :readonly="readonly"
                                  >
                    </fancy-select>
                </div>

                <div v-else>
                    <fancy-select :name="buildingPart.id"
                                  @input="setMeasure(buildingPart.id, groupName, arguments[0])"
                                  :value="getMeasure(buildingPart.id, groupName)"
                                  v-validate="'required'"
                                  :options="buildingPart.measures"
                                  :ref="'fancyselect' + buildingPart.id"
                                  :readonly="readonly"
                                  :nvtId="-1"
                                  :hasNvt="true">
                    </fancy-select>
                </div>
            </div>
            <div v-if="buildingPart.name == 'Verwarming' && Value == true" class="row">
                <div class="ketel">
                    <span class="label">Plaatsjaar ketel (optioneel)</span>
                    <input type="number" placeholder="Plaatsjaar" style="width: calc(100% - 415px)" v-model="cvPlaceYear" :readonly="readonly" />
                </div>
            </div>

            <div class="row" v-for="dimension in buildingPart.dimensions" :key="dimension.id">
                <span class="label">{{dimension.name | dimensionDisplay}}</span>
                <input :name="dimension.id"
                       type="text"
                       class="input-field w130p"
                       @input="setDimension(dimension.id, arguments[0].target.value)"
                       :value="dimensions[dimension.id]"
                       v-validate="'required|decimal'"
                       :readonly="readonly"
                       :disabled="isDisabled(buildingPart.id, groupName)">
                {{dimension.unit}}
            </div>

            <FileUploader :group-name="groupName" :buildingPartName="buildingPart.name" :readonly="readonly"/>
        </div>
    </div>
</template>

<script>
    import FancySelect from "./FancySelect.vue";
    import FileUploader from "../components/FileUploaderDef.vue";
    import { createHelpers } from "vuex-map-fields";

    const { mapFields } = createHelpers({
        getterType: "advisorDashboard/getField",
        mutationType: "advisorDashboard/updateField"
    });

    export default {
        components: {
            FancySelect,
            FileUploader
        },
        props: {
            measuresUiStates: {
                type: Object,
                default: () => new Object()
            },
            measures: {
                type: Object,
                default: () => null
            },
            dimensions: {
                type: Object,
                default: () => {}
            },
            schema: {
                type: Object,
                default: () => null
            },
            groupName: {
                type: String,
                default: () => ""
            },
            category: {
                type: String,
                default: () => ""
            },
            readonly: {
                type: Boolean,
                required: false,
                default: () => false
            }
        },
        data() {
            return {
                loaded: false,
                Value: false,
                bpDimensions: {}
            };
        },
        methods: {            
            isDisabled(id, groupName) {
                return this.measuresUiStates[id] && this.measuresUiStates[id][groupName] == -1;
            },
            heatingValue() {
                var i = document.querySelector("h4[data-building-part='Verwarming'] + div div.fancy-select span").innerText
                if (i == 'HR ketel' || i == 'VR ketel') {
                    this.Value = true;
                } else {
                    this.Value = false;
                }
            },
            deferUntil(checkFunc, runFunc, interval) {
                if (checkFunc()) {
                    runFunc();
                } else {
                    setTimeout(function () { this.deferUntil(checkFunc, runFunc, interval) }.bind(this), interval)
                }
            },
            getMeasure(buildingPartId, groupName) {
                if(this.measures[buildingPartId] === undefined || 
                   this.measures[buildingPartId] === null ||
                   this.measures[buildingPartId][groupName] === undefined ||
                   this.measures[buildingPartId][groupName] === null)
                    return null;
                return this.measures[buildingPartId][groupName];

            },
            setMeasure(buildingPartId, groupName, measureId) {
                if (this.measuresUiStates[buildingPartId] == undefined)
                    this.$set(this.measuresUiStates, buildingPartId, {});
                this.$set(this.measuresUiStates[buildingPartId], groupName, measureId);
                this.$emit('update-measure', buildingPartId, groupName, measureId);
            },
            setDimension(dimensionId, val) {
                let parsedValue = parseFloat(val.replace(",", "."));
                if(isNaN(parsedValue))
                    return;

                this.$emit("update-dimension", dimensionId, parsedValue);
            }
        },

        computed: {
            ...mapFields([
                'cvPlaceYear'
            ]),
            filteredBuildingParts() {
                if (this.schema == null || this.schema.buildingParts == null || this.schema.buildingParts.length == 0)
                    return [];

                var filtered = this.schema.buildingParts.filter(
                    (buildingPart) => buildingPart != null && buildingPart.dimensions != null && buildingPart.dimensions != undefined)

                return filtered.map((buildingPart) => {
                    buildingPart.dimensions = buildingPart.dimensions.filter((dimension) => dimension != null && dimension != undefined);
                    return buildingPart;
                })
            }
        },

        async mounted() {
            this.deferUntil(
                () => { return document.querySelector("h4[data-building-part='Verwarming'] + div div.fancy-select span") != null },
                () => {
                    var i = document.querySelector("h4[data-building-part='Verwarming'] + div div.fancy-select span").innerText
                    if (i == 'HR ketel' || i == 'VR ketel') {
                        this.Value = true;
                    } else {
                        this.Value = false;
                    }
                },
                500
            )
        },
        
        updated() {
            for(let i = 0; i < this.filteredBuildingParts.length; i++) {
                let buildingPart = this.filteredBuildingParts[i];
                let fancySelect = this.$refs['fancyselect' + buildingPart.id];
                if(fancySelect !== null && fancySelect !== undefined 
                && fancySelect.constructor.name === 'Array' && fancySelect.length > 0) {
                    fancySelect[0].setValue(this.getMeasure(buildingPart.id, this.groupName))
                }
            }
        }
    };
</script>

