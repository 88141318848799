import Vue from 'vue';
import Vuex from 'vuex';
import VueWait from 'vue-wait';
import createPersistedState from 'vuex-persistedstate';

import core from './modules/core';
import quickscan from './modules/quickscan';
import consumer from './modules/consumer';
import provisionalPlan from './modules/provisional-plan';
import dashboard from './modules/dashboard';
import advisorDashboard from './modules/advisor-dashboard';
import definitivePlan from './modules/definitive-plan';
import ui from './modules/ui';
import data from './modules/data';
import vouchers from './modules/vouchers';
import payment from './modules/payment';
import plan from './modules/plan';
import advicePlan from './modules/advice-plan';
import quotationPlan from './modules/quotation-plan';
import quotation from './modules/quotation';
import quotations from './modules/quotations';

// const vuexLocalStorage = new VuexPersist({
//   key: 'vuex',
//   storage: window.localStorage,
//   modules: ['consumer']
// })

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);
Vue.use(VueWait);

// always enable vue devtools
Vue.config.devtools = true;

const store = new Vuex.Store({
	modules: {
		ui,
		core,
		quickscan,
		consumer,
		dashboard,
    advisorDashboard,
    definitivePlan,
		provisionalPlan,
		plan,
		advicePlan,
		quotationPlan,
		quotation,
		vouchers,
		payment,
		quotations,
		data
	},
	strict: debug,
	plugins: [
		createPersistedState({
			paths: [ 'consumer', 'provisionalPlan', 'advisorDashboard.proposedMeasures' ]
		})
	]
});

const wait = new VueWait({
	useVuex: true // You must pass this option `true` to use Vuex
});

export { store, wait };
