<template>
    <div>
        
        <h4 v-if="current == true">Foto's huidige situatie</h4>
        <div v-if="uploadFileStorage != undefined && uploadFileStorage[groupName] != undefined && uploadFileStorage[groupName][buildingPartName] != undefined">
            <div v-for="(file, index) in uploadFileStorage[groupName][buildingPartName]" class="row img-upload-container" :data-index="index" :key="index">
                <span class="label">Voeg foto's toe</span>
                <div class="img-container">
                    <figure @click="showLightbox(index)">
                        <img v-if="file" :src="file.src" />
                        <span class="zoom"></span>
                    </figure>
                    <div class="text-links">
                        <a href="javascript:void(0)" @click="!readonly && removeFile(index)">Verwijder foto</a>
                    </div>
                    <textarea class="img-comment"
                              placeholder="Omschrijving bij de foto"
                              @input="updateComment(groupName, buildingPartName, index, $event.target.value)"
                              v-if="file"
                              :disabled="readonly">{{ file.comment }}
                </textarea>
                </div>
            </div>
        </div>

        <div style="padding-top:10px;text-align:right;">
            <input :disabled="readonly" class="inputfile" type="file" :id="id" ref="files" v-on:change="filesUpdate()" data-multiple-caption="{count} files selected" accept="image/jpg,image/jpeg,image/png" multiple />
            <label :for="id" v-on:click="upload()"><span class="upload">Foto's uploaden</span></label>
            <p style="padding-top: 10px; color: #0f324c; font-size: 15px ">
                <i>JPG-formaat, max. 10mb</i>
            </p>
            <div v-if="errors.length">
                <p v-for="error in errors" style="color:red;">{{ error }}</p>
            </div>            
        </div>

        <Lightbox :visible.sync="lightboxVisible" :images="lightboxImages" :current.sync="lightboxCurrent" />

    </div>
</template>
<script>
    import { mapState } from "vuex";
    import { mapWaitingActions } from "vue-wait";
    import Lightbox from '../../../components/Lightbox';

    export default {
        name: 'FileUploaderDef',

        props: {
            buildingPartName: String,
            groupName: {
                type: String,
                required: true,
            },
            readonly: {
                type: Boolean,
                required: false,
                default: () => false
            }
        },
        data: () => {
            return {
                id: null,
                files: [],
                readers: [],
                lightboxVisible: false,
                lightboxImages: [],
                lightboxCurrent: 0,
                errors: [],
            }
        },
        components: {
            Lightbox
        },
        computed: {
            ...mapState("core", ["user"]),
            ...mapState("advisorDashboard", ["uploadFileStorage"]),
            current: function () { return this.files.length > 0 },
        },
        methods: {
            ...mapWaitingActions("advisorDashboard", ["updateUploadFile", "updateUploadFileComment", "removeUploadFile"]),

            updateComment(groupName, buildingPartName, index, value) {
                this.updateUploadFileComment({ groupName, buildingPartName, index, comment: value });
            },
            showLightbox(current) {
                this.lightboxCurrent = current;
                this.lightboxVisible = true;
            },

            removeFile(index) {
                this.files.splice(index, 1);
                this.removeUploadFile({
                    groupName: this.groupName,
                    buildingPartName: this.buildingPartName,
                    index: index,
                });
                var readers = this.readers.filter(function (reader) { return reader.fileno == index });
                if (readers.length > 0) {
                    this.readers.splice(index, 1);
                }
                if (this.files.length > 0) {
                    this.$set(this.files, 0, this.files[0]);
                    var readersToUpdate = this.readers.filter(function (reader) { return reader.fileno > index; });
                    for (var i = 0; i < readersToUpdate.length; i++) {
                        readersToUpdate[i].fileno--;
                    }
                } else {
                    this.files = [];
                    this.readers = [];
                }
            },
            upload() {
                this.errors = [];
            },
            filesUpdate() {
                var files = Array.apply(null, document.querySelector("input[id='" + this.id + "']").files);

                if (files.some(file => file.size > 10000000 || (file.type !== 'image/jpeg' && file.type !== 'image/jpg'))) {
                    this.errors.push("De foto moet een JPG zijn van maximaal 10MB.");
                    return;
                }

                this.files.push.apply(this.files, files);

                if (this.readers == undefined || this.readers == null)
                    this.readers = [];

                for (var i = 0; i < files.length; i++) {

                    var reader = new FileReader();
                    this.readers.push(reader);
                    reader.fileno = this.readers.length - 1;

                    reader.onloadend = function () {
                        for (var i = 0; i < this.readers.length; i++) {
                            if (this.readers[i].readyState == 2 && this.readers[i].processed != true) {
                                var index = this.readers[i].fileno;
                                this.files[index].src = this.readers[i].result;
                                this.$set(this.files, index, this.files[index]);
                                this.updateUploadFile({
                                    groupName: this.groupName,
                                    buildingPartName: this.buildingPartName,
                                    index: index,
                                    src: this.files[index].src,
                                    file: this.files[index],
                                });
                                this.readers[i].processed = true;
                            }
                        }
                    }.bind(this);

                    if (files[i]) {
                        reader.readAsDataURL(files[i]);
                    }
                }
            },
            load() {
                this.files = [];
                this.readers = [];
                this.fileCounter = this.$refs["files"].files.length;
                this.id = this._uid;
                this.readers = this.readers || [];

                if (
                    !this.buildingPartName
                    || this.uploadFileStorage === null
                    || !(this.groupName in this.uploadFileStorage)
                    || !(this.buildingPartName in this.uploadFileStorage[this.groupName])
                ) {
                    return;
                }

                this.uploadFileStorage[this.groupName][this.buildingPartName].forEach((img) => {
                    this.files.push(img)
                    this.readers.push({ readyState: 2, processed: true });
                });
            },
        },
        mounted() {
           this.load(); 
        },
        watch: {
            files(files) {
                this.lightboxImages = files.map(file => {
                    return {
                        src: file.src
                    }
                });
            },
            buildingPartName() {
                this.load();
            },
            groupName() {
                this.load();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
</style>

