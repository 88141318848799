import api from '~/src/api';
import { getField, updateField } from 'vuex-map-fields';
import { paymentStatus } from '~/src/constants';

const state = {
	currentPlan: null,
	planState: null,
	currentMeasures: [],
	totalCost: undefined,
	consumerSituation: {},
	appointment: undefined,
	completePlanNavOverlayOpen: false,
  isAdvicePlan: false,
	paymentStatus: null,
};

const getters = {
  getField,

	planState(state) {

		return state.planState;
    },

	currentPlan: (state) => {
		return state.currentPlan;
    },
    currentMeasures: (state) => {
        return state.currentMeasures;
    },
	consumerSituation: (state) => {
		return state.consumerSituation;
	},
	totalCost: (state) => {
		return state.totalCost;
	},
	measureCount: (state) => {
		return state.currentMeasures.length;
    },
    isAdvicePlan: (state) => {
        return state.isAdvicePlan;
    },
	hasAppointment: (state) => {
		return state.appointment != null || state.isAdvicePlan;
	},
	appointmentDateSet: (state) => {
		return (state.appointment != null && state.appointment.appointmentDateTimeUtc) || state.isAdvicePlan;
	},
	appointmentDate: (state) => {
		if (state.appointment == null) return undefined;

		return state.appointment.appointmentDateTimeUtc;
	},
	hasPaid: (state) => {

		if (state.isAdvicePlan)
			return true;

		if (state.appointment == null) return false;

		return state.appointment.hasPaid;
	},

	bankTransferPending: (state) => {
    return state.paymentStatus != null && state.paymentStatus == paymentStatus.MANUAL_TRANSACTION_STARTED;
	},
};

const actions = {

	async loadCurrentPlan({ commit }) {
		await api.loadCurrentPlan().then((data) => {

			if (!data)
				return;
			
			commit('setCurrentPlan', data);
			commit('setCurrentState', data.state);
		});
	},

  async loadCurrentPlanState({ commit }) {
    await api.loadCurrentPlanState().then((state) => {
      commit('setCurrentState', state);
    });
  },
	async loadCurrentAppointment({ commit }) {
		await api.loadCurrentAppointment()
			.then((appointment) => {

				commit('setCurrentAppointment', appointment);
			})
			.catch(() => {

				commit('setCurrentAppointment', null);
			});
	},
	toggleCompletePlanNavOverlay({ commit }) {
		commit('toggleCompletePlanNavOverlay');
	},

	async loadPaymentStatus({ commit }) {

		await api.getPaymentStatus().then((data) => {

			commit('paymentStatus', data.paymentInfo.status);
		});
	}
};

const mutations = {
    updateField,
	setCurrentPlan(state, plan) {
		
		const planData = JSON.parse(plan.plan);

		state.currentPlan = planData;
		state.isAdvicePlan = plan.type == 'AdvicePlan';
		state.consumerSituation = planData.consumerSituation;
		state.currentMeasures = planData.terms[0].measures;
		state.totalCost = planData.totalCosts;
  },

	setCurrentState(state, planState) {
		state.planState = planState;
	},

	setCurrentAppointment(state, appointment) {
		state.appointment = appointment;
	},

	toggleCompletePlanNavOverlay(state) {
		state.completePlanNavOverlayOpen = !state.completePlanNavOverlayOpen;
	},

	paymentStatus(state, paymentStatus) {

		state.paymentStatus = paymentStatus;
	}
};

export default {
	namespaced: true,
	getters,
	state,
	actions,
	mutations
};
