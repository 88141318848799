import Vue from 'vue';
import { store, wait } from '../store';
import { mapWaitingActions } from 'vue-wait';
import { Settings } from 'luxon';
import Loader from './Loader.vue';

Settings.defaultLocale = 'nl';

import './core';

const appBase = {
	store,
	wait,
	methods: {
		...mapWaitingActions('core', [ 'loadSettings' ]),
		...mapWaitingActions('data', [ 'loadDataTypes' ])
	},
	async mounted() {
		await this.loadDataTypes();
		await this.loadSettings();
	}
};

Vue.component('Loader', Loader)

export default appBase;
