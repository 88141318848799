<template>
    <div class="fancy-select" @click="onClick()" @blur="onBlur()" @focus="onFocus()" tabindex="0">
        <span class="select-text">
            <i></i>
            {{label}}
        </span>
        <ul class="select" ref="list" :class="{active: isOpen}">
            <li v-for="option in mutableOptions" v-bind:key="option.id">
                <input :id="getKey(option)"
                       :key="getKey(option)"
                       type="radio"
                       v-model="mutableValue"
                       :name="name"
                       :value="option.id"
                       v-bind="$attrs">
                <label :for="getKey(option)">{{getLabel(option)}}</label>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        inheritAttrs: false,
        data: function() {
            return {
                isOpen: false,
                mutableValue: null,
                mutableOptions: [],
                debounce: false
            };
        },
        computed: {
            label() {
                if (this.mutableOptions) {
                    let option = this.mutableOptions.find(o => o.id == this.mutableValue);
                    return option ? (option.text || option.name) : null;
                }
                return null;
            }
        },
        methods: {
            onFocus() {
                if (!this.readonly)
                    this.isOpen = true;
            },
            onBlur() {
                this.isOpen = false;
            },
            getKey(option) {
                return `${this.buildingPartName}_${this.name}_${option.id}`;
            },
            getLabel(option) {
                return option.text || option.name;
            },
            onClick() {
                if (!this.readonly)
                    this.isOpen = true;
            },
            //used by AdvicePlanFinish parent to populate selected option on load
            setValue(value) {
                if (this.mutableOptions)
                    if (value === null || this.mutableOptions.some(o => o.id == value))
                        this.mutableValue = value;
            },
            closeDropdown() {
                this.isOpen = false;
            }
        },
        props: {
            buildingPartName: {
                default: ""
            },
            hasNvt: {
                default: false
            },
            nvtId: {
                default: null
            },
            value: {
                default: null
            },
            required: {
                default: false
            },
            name: {
                type: String
            },
            options: {
                type: Array,
                default() {
                    return [];
                }
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        watch: {
            value(val) {
                this.mutableValue = (val == "" ? null : val);
            },
            mutableValue(val, old) {
                if (val !== old) {
                    this.$emit("input", (val == "" ? null : val));
                    this.isOpen = false;
                }
            },
        },
        created() {
            this.mutableValue = this.value;
            if (this.hasNvt && this.options.length > 0)
                this.mutableOptions.push(
                    {
                        buildingPartId: this.options[0] ? this.options[0].buildingPartId : null,
                        id: this.nvtId,
                        text: 'n.v.t.',
                        name: 'n.v.t.',
                        labourCost: 0,
                        maintenanceCost: 0,
                        materialCost: 0,
                        materialVat: 0,
                    }
                );
            this.mutableOptions.push(...this.options);
        },
    };
</script>

