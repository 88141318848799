import Vue from 'vue';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import { DateTime } from 'luxon';

numeral.locale('nl-nl');

Vue.filter('formatPrice', (value) => {
	return numeral(Math.round(value)).format('$ 0,0');
});

Vue.filter('json', (value) => {
	return JSON.stringify(value);
});

Vue.filter('stripHtml', (value) => {
  var cleanMotivation;
  if (value == null) {
    cleanMotivation = "";
  } else {
    cleanMotivation = value.replace(/(<([^>]+)>)/g, '');
  }
  return cleanMotivation;
});

Vue.filter('reasonDisplay', (value) => {
	switch (parseInt(value)) {
		case 0:
			return 'problemen oplossen';
		case 1:
			return 'duurzaam moderniseren';
		case 2:
			return 'investeren';
		case 3:
			return 'onafhankelijker zijn';
	}

	return 'N.B.';
});

Vue.filter('ambitionDisplay', (value) => {
	switch (parseInt(value)) {
		case 1:
			return 'kosten-efficiëntie';
		case 2:
			return 'comfortverhoging';
		case 3:
			return 'maximale duurzaamheid';
	}
	return 'N.B.';
});

Vue.filter('dateDisplay', (value) => {
    return DateTime.fromISO(value).setZone('Europe/Amsterdam').toFormat('DDDD, T uur'); //utc because the rest of the system assumes times are UTC.
});

Vue.filter('shortDateDisplay', (value) => {
    return DateTime.fromISO(value).setZone('Europe/Amsterdam').toFormat('ccc d LLL yyyy, T uur'); //utc because the rest of the system assumes times are UTC.
});

Vue.filter('dateWithoutTime', (value) => {
    return DateTime.fromISO(value).toFormat('dd-MM-yyyy');
});

Vue.filter('number', (value) => {
	return numeral(value).format('0.0');
});

Vue.filter('currency', (value) => {
	return `€ ${numeral(value).format('0,0.00')}`;
});

Vue.filter('dimensionDisplay', (value) => {
	return value;

	//// if the value ends with verdieping, we'd like to output the first part!
	//if (value.endsWith('verdieping')) {
	//	return value.replace(/\s?-\s?[a-z\s]+/gi, '');
	//}

	//// dimension names begin with a name plus a dash
	//return value.replace(/^[a-z\s]+-\s?/gi, '');
});

Vue.filter('termDisplay', (value) => {
	switch (parseInt(value)) {
		case 0:
			return 'Korte termijn';
		case 1:
			return 'Middellange termijn';
		case 2:
			return 'Lange termijn';
	}
	return 'N.B.';
});


Vue.filter('lowerCase', value => {
	return (value + '').toLocaleLowerCase();
})

Vue.filter('stageOfLifeDisplay', value => {
   switch (parseInt(value)) {
     case 1:
       return '20-50 jaar';
     case 2:
       return '50-75 jaar';
     case 3:
       return '75+';
    }
  return 'N.B.';
})


Vue.filter('buildYearDisplay', value => {
  switch (parseInt(value)) {
    case 1:
      return 'voor 1940';
    case 2:
      return 'jaren 40 tot 75';
    case 3:
      return 'jaren 76 tot 85';
    case 4:
      return 'jaren 86 tot 95';
    case 5:
      return 'na 1995'
  }
  return 'N.B.';
})

Vue.filter('buildingTypeDisplay', value => {
  switch (parseInt(value)) {
    case 1:
      return '2-onder-1 kapwoning met kap';
    case 2:
      return '2-onder-1 kapwoning met plat dak';
    case 3:
      return 'hoekwoning met kap';
    case 4:
      return 'hoekwoning met plat dak';
    case 5:
      return 'tussenwoning met kap';
    case 6:
      return 'tussenwoning met plat dak';
    case 7:
      return 'vrijstaande woning met kap';
    case 8:
      return 'vrijstaande woning met plat dak';
  }
  return 'N.B.';
})

Vue.filter('price', value => {

    return '&euro;&nbsp;' + (Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2);
})