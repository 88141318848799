<template>
  <section class="max-width-medium content" style="border-bottom:100px solid transparent;">
    <div class="intro">
      <h3>Basis gegevens</h3>
      <p>Deze vragen zijn ingevuld door de klant, uw kunt aanpassingen doorvoeren. Doe dit altijd in overleg met de klant</p>
    </div>
    <v-wait>
      <form @submit.prevent="submit()">
        <div class="invalid-block" v-if="errors.any()">
          <b>Vul alle velden in:</b> Een aantal velden zijn niet of niet juist ingevuld. Controleer de verschillende velden
        </div>

        <h4>Ingevulde gegevens</h4>
        <div class="row">
          <span class="label">Postcode + huisnr.</span>
          <input
            type="text"
            class="input-field w130p"
            v-model="client.zipCode"
            readonly
            v-validate="'required'"
          >
          <input
            type="text"
            class="input-field w130p"
            v-model="client.houseNumber"
            readonly
            v-validate="'required'"
          >
        </div>

        <div class="row">
          <span class="label">Straatnaam</span>
          <input
            type="text"
            class="input-field auto100"
            v-model="client.streetName"
            readonly
            v-validate="'required'"
          >
        </div>

        <div class="row">
          <span class="label">Plaats</span>
          <input
            type="text"
            class="input-field auto100"
            v-model="client.city"
            readonly
            v-validate="'required'"
          >
        </div>

        <div class="row">
          <span class="label">Voorschotbedrag</span>
          <input
            type="number"
            class="input-field w130p"
            v-model="monthlyDeposit"
            v-validate="'required'"
            :readonly="readonly"
          >
          p/mnd
        </div>

        <div class="row">
          <span class="label">Hoe energiezuinig ben je?</span>
          <fancy-select
            name="energyEfficiency"
            :options="energyEfficiencies"
            v-model="energyEfficiency"
            :readonly="readonly"
          ></fancy-select>
        </div>

        <h4>Woning details</h4>
        <div class="row">
          <span class="label">Bouwjaar</span>
          <fancy-select name="buildYear" :options="buildYears" v-model="buildYear" :readonly="readonly"></fancy-select>
        </div>
        <div class="row">
          <span class="label">Type woning</span>
          <fancy-select name="buildingType" :options="buildingTypes" v-model="buildingType" v-validate="'required'" :required ="true" :readonly="readonly"></fancy-select>
        </div>
        <div class="row">
          <span class="label">Woningoppervlakte</span>
          <input
            type="number"
            class="input-field w130p"
            v-model="areaOfUse"
            v-validate="'required'"
            :readonly="readonly"
          >
          m<sup>2</sup>
        </div>

        <h4>Woonsituatie</h4>
        <div class="row">
          <span class="label">Aantal volwassenen</span>
          <input
            type="number"
            class="input-field w130p"
            v-model="numberOfAdults"
            v-validate="'required'"
            :required ="true"
            :readonly="readonly"
          >
        </div>
        <div class="row">
          <span class="label">Levensfase volwassenen</span>
          <fancy-select
            name="stageOfLife"
            :options="lifeStages"
            v-model="stageOfLife"
            v-validate="'required'"
            :required ="true"
            :readonly="readonly"
          ></fancy-select>
        </div>
        <div class="row">
          <span class="label">Aantal kinderen</span>
          <input
            type="number"
            class="input-field w130p"
            v-model="numberOfChildren"
            v-validate="'required'"
            :required ="true"
            :readonly="readonly"
          >
        </div>

        <div class="sticky-footer light">
            <div class="btn-row max-width-medium">
                <a class="btn light left" @click.prevent="goBack()">Terug</a>
                <FooterNavDropdown v-if="!isUserDashboard" label="1. Basisgegevens"></FooterNavDropdown>
                <button class="btn dark right" :disabled="$wait.any">Verder</button>
            </div>
        </div>
      </form>
    </v-wait>
  </section>
</template>

<script>
import ComponentBase from "~/src/components/ComponentBase.vue";
import FancySelect from "../components/FancySelect.vue";
import FooterNavDropdown from "../components/FooterNavDropdown.vue";
import { createHelpers } from "vuex-map-fields";
import { mapWaitingActions } from "vue-wait";

// create a helper for our consumer module
const { mapFields: mapDataFields } = createHelpers({
  getterType: "data/getField",
  mutationType: "data/updateField"
});
const { mapFields } = createHelpers({
  getterType: "advisorDashboard/getField",
  mutationType: "advisorDashboard/updateField"
});

export default {
  mixins: [ComponentBase],
  components: {
    FancySelect,
    FooterNavDropdown
  },
  props: {
      isUserDashboard: {
          type: Boolean,
          required: false,
          default: () => false
      },
      readonly: {
          type: Boolean,
          required: false,
          default: () => false
      }
  },
  computed: {
    ...mapDataFields(["energyEfficiencies"]),
    ...mapFields([
      "client",
      "areaOfUse",
      "buildingType",
      "buildYear",
      "ambition",
      "monthlyDeposit",
      "energyEfficiency",
      "numberOfAdults",
      "numberOfChildren",
      "stageOfLife",
      "reason",
      'clientPlan',
    ]),
    consumer() {
      return this.clientPlan.consumerSituation || {};
      },
      planEmpty() {
          return this.clientPlan === null ||
              this.clientPlan.consumerSituation === null ||
              this.clientPlan.dimensionValues === null ||
              this.clientPlan.terms === null || this.clientPlan.terms.length === 0
      }
  },
  mounted() {
      window.localStorage.removeItem('nullMeasures')
  },
  methods: {
    ...mapWaitingActions("advisorDashboard", ["updateAdvisePlanSituation", "updateConsumerSituation"]),
    async submit() {
      let result = await this.finishStep1();
      if(!result)
        return;
      if(this.isUserDashboard)
          this.$emit('next');
      else
          this.$router.push({ name: "advise-plan-step2", query: { readonly: this.readonly.toString() } });
    },
    goBack() {
      if (this.isUserDashboard)
        this.$emit('back');
      else
        this.$router.go(-1);
    },
    getValues(list) {
      return list.map(v => v.id);
    },
    getLabel(list, option) {
      return list.find(v => v.id == option).text;
    },
    getBuildingTypeLabel(option) {
      return this.getLabel(this.buildingTypes, option);
    },
    getBuildYearLabel(option) {
      return this.getLabel(this.buildYears, option);
    },
    getStageOfLifeLabel(option) {
      return this.getLabel(this.lifeStages, option);
    },
    async finishStep1() {
      let result = await this.$validator.validateAll();

        if (!result) {
            return false;
        }

        let measuresObject = {};
        if (!this.planEmpty) {
            this.clientPlan.consumerSituation.measures.forEach((measure) => {
                if(measuresObject[measure.key] === undefined || measuresObject[measure.key] === null)
                  measuresObject[measure.key] = {};
                measuresObject[measure.key][measure.group] = measure.value;
            });

            await this.updateAdvisePlanSituation({
                measures: measuresObject,
                dimensions: this.clientPlan.dimensionValues !== null ? this.clientPlan.dimensionValues : {}
            });
        }

        await this.updateConsumerSituation({
            consumerSituation: {
                monthlyDeposit: this.monthlyDeposit,
                energyEfficiency: this.energyEfficiency,
                areaOfUse: this.areaOfUse,
                buildingType: this.buildingType,
                buildYear: this.buildYear,
                ambition: this.ambition,
                reason: this.reason,
                numberOfAdults: this.numberOfAdults,
                numberOfChildren: this.numberOfChildren,
                stageOfLife: this.stageOfLife,
                cvPlaceYear: this.clientPlan.consumerSituation != null && this.clientPlan.consumerSituation.cvPlaceYear != null ?
                    this.clientPlan.consumerSituation.cvPlaceYear : 0,
                measures: measuresObject
            },
            dimensions: this.clientPlan.dimensionValues !== null ? this.clientPlan.dimensionValues : {}
        });

        return true;
    }
  },
};
</script>
