<template>
  <span class="avatar" :class="getAvatarClass()">{{firstLetter}}</span>
</template>
<script>
export default {
  props: {
    hash: Number,
    firstName: String
  },
  computed: {
    firstLetter() {
      if (this.firstName) return this.firstName[0];
      return "";
    }
  },
  methods: {
    getAvatarClass() {
      return `bg-${this.hash}`;
    }
  }
};
</script>