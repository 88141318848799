<template>

    <div v-else class="component component--avatar avatar">

        <img :src="`${img}?width=${size}&height=${size}&mode=min&autorotate=true`" class="avatar__image">

    </div>

</template>

<script>

    export default {
        props: {
            img: {
                type: String,
                required: true,
            },
            size: {
                type: Number,
                default: 44,
            },
        },
    };

</script>