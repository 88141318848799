<template>
  <a class="circel-link" @click.prevent="scrollDown()">
    <img src="/assets/icons/arrow-down.svg">
  </a>
</template>

<script>
export default {
  methods: {
    scrollDown() {
      let firstSection = document.querySelectorAll("section")[0];
      if (firstSection) {
        firstSection.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }
};
</script>

