import AdvicePlanStep2 from './views/AdvicePlanStep2.vue';

const routeDefs = [
	{
		category: 'Isolatie',
		groupName: 'Zolder'
	},
	{
		category: 'Isolatie',
		groupName: 'Verdieping'
	},
	{
		category: 'Isolatie',
		groupName: 'Begane grond'
	},
	{
		category: 'Isolatie',
		groupName: 'Achtergevel'
	},
	{
		category: 'Isolatie',
		groupName: 'Rechter zijgevel'
	},
	{
		category: 'Isolatie',
		groupName: 'Linker zijgevel'
	},
	{
		category: 'Isolatie',
		groupName: 'Voorgevel'
	},
	{
		category: 'Installatie',
		groupName: ''
	},
	{
		category: 'Overige',
		groupName: ''
	},
];

const makePath = (routeDef) => {
	let { groupName, category } = routeDef;

	const santizeFn = (val) => {
		return val.toLowerCase().replace(/\s/, '-');
	};

	return `${santizeFn(category)}/${santizeFn(groupName)}`;
};

const makeDisplay = (routeDef) => {
	let { groupName, category } = routeDef;

	if (groupName) {
		return `${category} per bouwdeel: ${groupName.toLowerCase()}`;
	}
	return category;
};

const makeRoute = (routeDef) => {
	let { groupName, category } = routeDef;
	//if(category )
	if (groupName) {
		return {
			name: `${groupName}:${category}`,
			path: makePath(routeDef),
			component: AdvicePlanStep2,
			display: makeDisplay(routeDef),
			props:
				route => (
					{
						groupName,
						category,
						readonly: route.query.readonly == 'true'
					}),
			category
		}
	} else {
		return {
			name: `${category}`,
			path: makePath(routeDef),
			component: AdvicePlanStep2,
			display: makeDisplay(routeDef),
			props: route => (
				{
					category,
					groupName: undefined,
					readonly: route.query.readonly == 'true'
				}),
			category
		}
	}
};

const routes = routeDefs.map(makeRoute);
export { routes, routeDefs };
