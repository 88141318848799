import { store } from '../../store';

let filteredBuildingParts = [];
let filteredMeasures = [];

const filterBuildingParts = (categoryName) => {
  window.state = JSON.parse(JSON.stringify(store.state));
	let category = store.state.data.buildingPartCategories.find((c) => c.name === categoryName);
	if (!category) return;

	filteredBuildingParts = store.state.data.buildingParts.filter((p) => p.buildingPartCategoryId == category.id);

	let buildingPartIds = filteredBuildingParts.map((x) => x.id);
	filteredMeasures = store.state.advisorDashboard.measures.filter((m) => {
		return buildingPartIds.indexOf(m.buildingPartId) !== -1;
	});
};

const getBuildingParts = (groupName) => {
	// filter out buildingparts not in the current category
	if (groupName) {
		let dimensions = store.state.data.buildingPartDimensions.filter((m) => m.groupName === groupName);
		let buildingPartIds = dimensions.map((m) => m.buildingPartId);

		return filteredBuildingParts.filter((p) => {
			return buildingPartIds.indexOf(p.id) !== -1;
		});
	}
	return filteredBuildingParts.sort((a, b) => a.advicePlanOrder - b.advicePlanOrder);
};

const getDimensions = (buildingPart, groupName) => {
	return store.state.data.buildingPartDimensions.filter(
		(d) => d.groupName === groupName && d.buildingPartId === buildingPart.id
	);
};

const getMeasures = (buildingPart) => {
	return filteredMeasures.filter((m) => {
		return m.buildingPartId == buildingPart.id;
	});
};

const kvpArrayToObject = (arr) => {
	let obj = {};
	arr.forEach((d) => {
		if(obj[d.key] === undefined)
      obj[d.key] = {};

		//This code migrates from old buildingPart -> measureId format to new buildingPart -> groupName -> measureId format.
    if (d.group === undefined || d.group === null || d.group.constructor.name != 'String') {
      store.state.data.buildingPartDimensions.filter((dim) => dim.buildingPartId === d.key).forEach((dimension) => {
        obj[d.key][dimension.groupName] = d.value;
      });
    } else {
      obj[d.key][d.group] = d.value;
    }
  });
	return obj;
};

const build = async (plan, category, group) => {

	var buildingPartOrder = null;
	
	switch (category) {

		case 'Isolatie':

			if (['Achtergevel', 'Rechter zijgevel', 'Linker zijgevel', 'Voorgevel'].includes(group)) {

				buildingPartOrder = store.getters['data/insulationPlanOrderName'];
			}
			break;

		case 'Installatie':

			buildingPartOrder = store.getters['data/installationPlanOrderName'];
			break;
	}
	
	await store.dispatch('data/loadDataTypes', buildingPartOrder);

	filterBuildingParts(category);

	let schema = {
		buildingParts: [],
		dimensionValues: plan.dimensionValues
	};

	getBuildingParts(group).forEach((b) => {
		b.measures = getMeasures(b);
		b.dimensions = getDimensions(b, group);

		schema.buildingParts.push(b);
	});

  schema.selectedMeasures = kvpArrayToObject(plan.consumerSituation.measures);

	return schema;
};
export default {
	build
};
