import api from '../../api'
import { getField, updateField } from 'vuex-map-fields';


const state = {
    allBuildingTypes: null,
    buildingTypes: null,
    lifeStages: null,
    buildYears: null,
    urls: {},
    user: {}
}

const getters = {
    getField,

    urls(state) {

        return state.urls;
    },

    userId: (state) => {
        return state.user.id;
    }
}

const actions = {
    openOverlay({commit}, element){
        commit('openOverlay', element.innerHtml);
    },
    closeOverlay({commit}){
        commit('closeOverlay');
    },
    async loadSettings({commit}){
        let settings = await api.loadSettings();
        commit('updateSettings', settings);
    }
}

const mutations = {
    updateField,
    updateSettings(state, settings) {
        state.urls = settings.urls;
        state.user = settings.user;
    },
    openOverlay(state, html) {
        state.overlayContent = html;
        state.overlayOpen = true;
    },
    closeOverlay(state) {
        state.overlayOpen = false;
    }
}

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}