<template>
    <div>
        <figure class="wide">
            <img :src="image">
            <figcaption>
                <BaseMeasureIcon :measure="measure" round></BaseMeasureIcon>
                {{measure.name}}
            </figcaption>
        </figure>
        <div class="content light">
            <p>{{measure.description}}</p>

            <dl class="stars">
                <dt>Kwaliteit</dt>
                <dd>
                    <span :style="getStarStyle(measure.quality)"></span>
                </dd>
                <dt>Kosten-efficiëntie</dt>
                <dd>
                    <span :style="getStarStyle(measure.starsCostEfficiency)"></span>
                </dd>
                <dt>Comfortverhoging</dt>
                <dd>
                    <span :style="getStarStyle(measure.starsComfort)"></span>
                </dd>
                <dt>Duurzaamheid</dt>
                <dd>
                    <span :style="getStarStyle(measure.starsDurability)"></span>
                </dd>
            </dl>
        </div>
    </div>
</template>

<script>

    import api from "@/api";

    export default {

        name: 'CustomMeasureOverlay',

        props: {
            content: Object,
        },

        data() {
            return {
                measure: {}
            };
        },

        watch: {

            content: {
                immediate: true,
                async handler(val) {
                    let measure = await api.getMeasure(val.measureId);
                    this.measure = measure;
                },
            },
        },

        computed: {

            image() {
                if (this.content == null || this.content.imageName == null)
                    return "/assets/img/aside-zonnepanelen.png";

                return `/assets/img/maatregelen/${this.content.imageName}?width=600`;
            },
        },

        methods: {

            getStarStyle(val) {
                let width = 0;
                if (val) {
                    width = 100 - 100 / val;
                }
                return { width: width + "%" };
            },
        },
    };
</script>
