<template>
  <button class="btn" @click.prevent="showOverlay()">{{title}}</button>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      body: null
    };
  },
  props: {
    title: String
  },
  methods: {
    showOverlay() {
      this.$store.dispatch("ui/openTooltipOverlay", {
        title: this.title,
        body: this.body.$el.outerHTML
      });
    }
  },
  mounted() {
    var vnode = this.$slots.default[0];
    var node = Vue.extend({
      props: ["node"],
      render() {
        return this.node ? this.node : "";
      }
    });

    const render = new node({
      propsData: {
        node: vnode
      }
    });

    render.$mount();
    this.body = render;
  },
  beforeDestroy() {
    if (this.body) {
      this.body.$destroy();
    }
  }
};
</script>
