<template>
  <section class="max-width finish-plan" style="margin-top:118px;">
    <div class="quad-col">
      <Sidenav />
      <div class="triple col light">
        <div class="content" v-if="loaded">
          <div class="plan-header">
            <h2>Advies Plan</h2>
            <p>Doel: {{clientPlan.consumerSituation.reason|reasonDisplay}} en {{clientPlan.consumerSituation.ambition | ambitionDisplay}}</p>
          </div>
          <BaseAccordion class="product-list" :calculate-height="false">
            <AdvicePlanDetailTerm
              :term="0"
              :measures="proposedMeasuresWithoutNvt[0]"
              :all-measures="allMeasures"
              :total-costs-changed="totalsChanged"
            />
            <AdvicePlanDetailTerm
              :term="1"
              :measures="proposedMeasuresWithoutNvt[1]"
              :all-measures="allMeasures"
              :total-costs-changed="totalsChanged"
            />
            <AdvicePlanDetailTerm
              :term="2"
              :measures="proposedMeasuresWithoutNvt[2]"
              :all-measures="allMeasures"
              :total-costs-changed="totalsChanged"
            />

            <div class="result-list">

              <dl class="left-block">
                <dt>Totale investering:</dt>
                <dd>{{total | currency}}</dd>
              </dl>

              <div class="right-block">
                <h5>
                  Benieuwd hoe
                  <br>je dit kunt financieren?
                </h5>
                <a disabled class="arrow-textlink">Bekijk financierings opties</a>
              </div>
            </div>
          </BaseAccordion>
        </div>
        <Loader v-else/>
      </div>

      <AdvicePlanDetailAside :plan="clientPlan" :client="client"></AdvicePlanDetailAside>
    </div>

    <div class="divider spacer large"></div>

    <footer class="sticky-footer border-top">
        <div class="max-width btn-row triple-btn-row">

            <form v-if="!readonly">
                <a @click.prevent="finishPlan" class="btn dark highlight">
                    Plan opslaan<i> & bevestigen</i>
                </a>
            </form>

            <form v-if="!readonly">
                <router-link :to="{name: 'finish:concept'}" class="btn dark">
                    <i>Plan opslaan als </i>concept
                </router-link>
            </form>
            <form>
                <router-link v-if="readonly" :to="{name: 'home'}" @click.native="removeClass()" class="btn dark">
                    Terug naar overzicht
                </router-link>
            </form>
            <form>
                <a @click.native="removeClass()" class="btn light" @click="$router.go(-1)">
                    Terug
                </a>
            </form>
        </div>
    </footer>
  </section>
</template>

<script>
import AdvicePlanDetailAside from "../components/AdvicePlanDetailAside.vue";
import AdvicePlanDetailTerm from "../components/AdvicePlanDetailTerm.vue";
import Sidenav from '../components/Sidenav.vue';
import axios from 'axios';

import { mapState } from "vuex";
import { mapWaitingActions } from "vue-wait";

export default {
  components: {
    AdvicePlanDetailAside,
    AdvicePlanDetailTerm,
    Sidenav
        },
  props: {
      readonly: {
          type: Boolean,
          required: false,
          default: () => false
      }
  },
  data() {
    return { allMeasures: [], totalTerm1: 0, totalTerm2: 0, totalTerm3: 0 };
  },
  methods: {
    ...mapWaitingActions("data", ["loadDataTypes"]),
    ...mapWaitingActions("advisorDashboard", [
      "loadClient",
      "loadClientPlan",
      "loadMeasures"
    ]),
    totalsChanged(term, total) {
      if (total) {
        switch (term) {
          case 0:
            this.totalTerm1 = total;
            break;
          case 1:
            this.totalTerm2 = total;
            break;
          case 2:
            this.totalTerm3 = total;
            break;
        }
      }
    },
    removeClass() {
      var i = document.querySelector("body.light");
      if  ( i != null ) {
        document.querySelector("body.light").classList.remove("light");
      } 
    },
    finishPlan() {
        document.querySelectorAll('.accordion-link:not(.active)').forEach((button) => button.click());
        deferUntil(
        () => Array.from(document.querySelectorAll('.accordion-link')).every((button) => button.classList.contains('active')),
        () => {
            document.querySelectorAll('.fold-button:not(.open)').forEach((button) => button.click());
            deferUntil(
            () => Array.from(document.querySelectorAll('.fold-button')).every((button) => button.classList.contains('open')),
            () => {
                let pdfNode = document.querySelector('.max-width.finish-plan').cloneNode(true);
                pdfNode.removeAttribute('style');
                pdfNode.querySelector('.sticky-footer').remove()
                pdfNode.querySelectorAll('.more-info').forEach((button) => button.remove())
                pdfNode.querySelector('.plan-header h2').textContent = "Energetisch Adviesplan";
                pdfNode.querySelectorAll('.product-item').forEach((node) => node.setAttribute('style', 'page-break-inside: avoid'));
                pdfNode.querySelectorAll(".item-savings span").forEach((span) => span.textContent = span.textContent.replace("Totale investering: ", "Kostenindicatie: "));
                pdfNode.querySelector(".result-list .left-block dt").textContent = pdfNode.querySelector(".result-list .left-block dt").textContent.replace("Totale investering:", "Indicatie totale kosten:");
                pdfNode.querySelector('.result-list .right-block h5').remove();
                pdfNode.querySelector('.result-list .right-block a.arrow-textlink').remove();

                var pdfDoc = document.implementation.createHTMLDocument("PDF");
                pdfDoc.head.innerHTML = document.head.innerHTML;
                pdfDoc.body.innerHTML = pdfNode.outerHTML;
                pdfDoc.body.setAttribute('class', 'light dashboard');
                pdfDoc.body.setAttribute('style', 'background: #f5f5f5');

                axios.post("/api/v1/quotation/set-eap-pdf/" + this.$route.params.id, JSON.stringify(pdfDoc.documentElement.outerHTML), { headers: { 'Content-Type': 'application/json' } })
                    .then(() => this.$router.push({ name: 'finish:save' }), () => alert("er ging iets mis. Probeer het a.u.b. opnieuw of neem contact op met Energiek Zeeland"));
            },
            150);
        },
        150);
    }
  },
  computed: {
    ...mapState("data", ["buildingParts"]),
    ...mapState("advisorDashboard", [
      "clientPlan",
      "client",
      "proposedMeasures"
    ]),
    loaded () {
      return this.buildingParts && this.clientPlan && this.client && this.proposedMeasures;
    },
    total() {
      return this.totalTerm1 + this.totalTerm2 + this.totalTerm3;
    },
    proposedMeasuresWithoutNvt() {
        return Object.keys(this.proposedMeasures).map(m => this.proposedMeasures[m].filter( n => n.measureId != -1) );
    },
  },
  async mounted() {
    await this.loadDataTypes();
    await this.loadClient(this.$route.params.id);
    await this.loadClientPlan(this.$route.params.id);

    this.allMeasures = await this.loadMeasures();
  }
};
</script>

