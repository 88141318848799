import api from '../../api'

const state = {
    quotations: null,
};

const getters = {

    quotations(state) {

        return state.quotations;
    },
};

const actions = {

    async loadQuotations({ commit }) {

        // TODO
        commit('quotations', [{}]);
        /*
        await api.getQuotations().then((quotations) => {
            
            commit('quotations', quotations);
        });
        */
    },

    getExportUrl(_, { startDate, endDate }) {

        return api.getQuotationsExportUrl(startDate, endDate);
    },
};

const mutations = {

    quotations(state, quotations) {

        state.quotations = quotations;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};