import api from '../../api'

const state = {
    groups: null,
};

const getters = {

    groups(state) {

        return state.groups;
    },
};

const actions = {

    async loadGroups({ commit }) {

        await api.getVoucherGroups().then((groups) => {
            groups.sort((a, b) => b.id - a.id);
            commit('groups', groups);
        });
    },

    async getGroup(_, groupId) {

        return api.getVoucherGroup(groupId);
    },

    async createGroup(_, { name, validFrom, validTo, discount, amount }) {

        return api.createVoucherGroup(name, validFrom, validTo, discount, amount);
    },

    async updateGroup(_, { groupId, name, validFrom, validTo }) {
        
        return api.updateVoucherGroup(groupId, name, validFrom, validTo);
    },

    async addVouchersToGroup(_, { groupId, amount }) {

        return api.addVouchersToGroup(groupId, amount);
    },

    getVouchersExportUrl(_, { groupId, fileType }) {

        return api.getVouchersExportUrl(groupId, fileType);
    },
};

const mutations = {

    groups(state, groups) {

        state.groups = groups;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};