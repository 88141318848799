import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';
import api from '~/src/api';

const state = {
	buildingType: null,
	buildYear: null,
  ownershipType: null,
  monument: null,
	measures: null,
	numberOfAdults: null,
	numberOfChildren: null,
	stageOfLife: null,
	step1Completed: false,
	step2Completed: false,
	step3Completed: false,
	step4Completed: false,
	zipCodeNumbers: null,
	zipCodeLetters: null,
	monthlyDeposit: null,
	energyEfficiency: null,
	fullName: null,
	houseNumber: null,
	streetName: null,
	zipCode: null,
	city: null,
	phoneNumber: null,
	emailAddress: null,
	usageEle: null,
	usageGas: null,
	areaOfUse: null,
	reason: null,
	ambition: null,
	currentPlan: null,
	cvPlaceYear: null,
};

const getters = {

	getField,

	fullName(state) {

		return state.fullName;
	},

	streetName(state) {

		return state.streetName;
	},

	houseNumber(state) {

		return state.houseNumber;
	},

	zipCode(state) {

		return state.zipCode;
	},

	city(state) {

		return state.city;
	},

	phoneNumber(state) {

		return state.phoneNumber;
	},

	emailAddress(state) {

		return state.emailAddress;
	},

	paymentReference: state => {

		return (state.zipCode + state.houseNumber).toUpperCase();
	},
};

const actions = {
	markStep1Completed({ commit }) {
		commit('markStep1Completed');
	},
	saveQuickScanStep2Data({ commit }, data) {
		commit('saveQuickScanStep2Data', data);
	},
	setBaseData({ commit }, data) {
		commit('updateField', { path: 'zipCode', value: data.zipCode });
		commit('updateField', { path: 'usageEle', value: data.usageEle });
		commit('updateField', { path: 'usageGas', value: data.usageGas });
	},
	setAddressInfo({ commit }, data) {
    commit('updateField', { path: 'houseNumber', value: data.houseNumber });
		commit('updateField', { path: 'streetName', value: data.streetName });
    commit('updateField', { path: 'zipCode', value: data.postalCode });
    commit('updateField', { path: 'city', value: data.city });
	},
	loadDataFromClient({ commit }, client) {
		commit('updateField', { path: 'houseNumber', value: client.houseNumber });
		commit('updateField', { path: 'streetName', value: client.streetName });
		commit('updateField', { path: 'zipCode', value: client.zipCode });
		commit('updateField', { path: 'city', value: client.city });
  },
	async loadConsumer({ commit }) {

		await api.loadCurrentUser().then((consumer) => {

			commit('fullName', consumer.fullName);
			commit('updateField', { path: 'city', value: consumer.city });
			commit('updateField', { path: 'streetName', value: consumer.streetName });
			commit('updateField', { path: 'houseNumber', value: consumer.houseNumber });
			commit('updateField', { path: 'zipCode', value: consumer.zipCode });
			commit('phoneNumber', consumer.phoneNumber);
			commit('emailAddress', consumer.emailAddress);
		});
	}
};

const mutations = {
	updateField,
	saveQuickScanStep1Data() {},
	saveQuickScanStep2Data(state, data) {
		Vue.set(state, 'measures', Object.assign({}, data));
	},
	markStep1Completed(state) {
		state.step1Completed = true;
		state.step2Completed = false;
		state.step3Completed = false;
		state.step4Completed = false;
	},
	markStep2Completed(state) {
		state.step1Completed = true;
		state.step2Completed = true;
		state.step3Completed = false;
		state.step4Completed = false;
	},
	markStep3Completed(state) {
		state.step1Completed = true;
		state.step2Completed = true;
		state.step3Completed = true;
		state.step4Completed = false;
	},
	markStep4Completed(state) {
		state.step1Completed = true;
		state.step2Completed = true;
		state.step3Completed = true;
		state.step4Completed = true;
	},

	fullName(state, fullName) {

		state.fullName = fullName;
	},

	phoneNumber(state, phoneNumber) {

		state.phoneNumber = phoneNumber;
	},

	emailAddress(state, emailAddress) {

		state.emailAddress = emailAddress;
	},
};

export default {
	namespaced: true,
	getters,
	state,
	actions,
	mutations
};
