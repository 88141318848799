const state = {
	overlayContent: null,
	overlayOpen: false,
	overlayComponentName: null,
	sideNavOpen: false,
	showDashboardFooter: true
};

const getters = {
	sideNavOpen(state) {
		return state.sideNavOpen;
	},
};

const actions = {
	toggleSideNav({ commit }) {
		commit('toggleSideNav');
	},
	openPlanAppointmentOverlay({ commit }, user) {
		commit('openOverlay', { content: user, componentName: 'BasePlanAppointmentOverlay' });
  },
  openAppointAmbassadorOverlay({ commit }, user) {
    commit('openOverlay', { content: user, componentName: 'BaseAppointAmbassadorOverlay' });
  },
	openTooltipOverlay({ commit }, content) {
		commit('openOverlay', { content: content, componentName: 'BaseTooltipOverlay' });
	},
	openMeasureOverlay({ commit }, measure) {
		commit('openOverlay', { content: measure, componentName: 'BaseMeasureInfoOverlay' });
	},

	openQuotationsExportOverlay({ commit }) {
		commit('openOverlay', { componentName: 'BaseQuotationsExportOverlay' });
	},

	openOverlay({ commit }, {content, componentName}) {
		commit('openOverlay', { content, componentName });
	},
	closeOverlay({ commit }) {
		commit('closeOverlay');
	},

	closeSideNav({ commit }) {
		commit('closeSideNav');
	},

	hideDashboardFooter({ commit }) {
		commit('hideDashboardFooter')
	},

	showDashboardFooter({ commit}) {
		commit('showDashboardFooter')
	}
};

const mutations = {
	openOverlay(state, { content, componentName }) {
		state.overlayContent = content;
		state.overlayComponentName = componentName;
		state.overlayOpen = true;
	},
	closeOverlay(state) {
		state.overlayOpen = false;
	},
	toggleSideNav(state) {
		state.sideNavOpen = !state.sideNavOpen;
	},

	closeSideNav(state) {

		state.sideNavOpen = false;
	},

	hideDashboardFooter(state) {
		state.showDashboardFooter = false;
	},

	showDashboardFooter(state) {
		state.showDashboardFooter = true;
	}
};

export default {
	namespaced: true,
	getters,
	state,
	actions,
	mutations
};
