import api from '../../api';

const GET_PARTNER = "realizationPartner";
const GET_PARTNERS = "realizationPartners";

const state = {
    plan: null,
    client: null,
    partner: null,
    partners: null
};

const getters = {

    url(state, getters, rootState, rootGetters) {
        
        return rootGetters['core/urls'].definitivePlanUrl;
    },
};

const actions = {
  async loadPlan({ commit }, id = null) {
        if (id != null) {
          await api.loadAdvicePlan(id).then((plan) => {
            commit('setCurrentPlan', plan);
            return plan;
          });
          return;
        }
        await api.loadAdvicePlan().then((plan) => {
          commit('setCurrentPlan', JSON.parse(plan.plan));
          return plan;
        });
    },
    async loadClient({ commit }, id = null) {
        if (id != null) {
            await api.loadClient(id).then((client) => {
                commit('setClient', client);
            })
            return;
        }
        await api.loadSettings().then((settings) => {
          commit('setClient', settings.user);
        });
    },
    async loadPartner({ commit }, id) {
        let partner = await api.getPartner(id);
        commit(GET_PARTNER, partner);
        return partner;
    },
    async loadPartners({ commit }) {
        let partners = await api.getAllPartners();
        commit(GET_PARTNERS, partners);
        return partners;
    },
}

const mutations = {
  setCurrentPlan(state, plan) {
    state.plan = JSON.parse(plan.plan);
    },
    setClient(state, client) {
        state.client = client;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}