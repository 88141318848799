<template>
  <div class="col aside-content-block" style="padding-top:55px;" v-if="loaded">
    <div class="aside-content">
      <AvatarIcon :firstName="client.firstName" :hash="client.avatarHash"/>
      <h4>{{client.firstName}} {{client.lastName}}</h4>
      <p>
        {{client.streetName}} {{client.houseNumber}}, {{client.zipCode}}, {{client.city}}
        <br>
        Telefoonnummer: {{client.phoneNumber}}
        <br>E-mailadres:
        <a :href="mailToLink">{{client.emailAddress}}</a>
      </p>
    </div>
    <div class="aside-content">
      <h5>Je huidige situatie</h5>
      <p>{{client.streetName}} {{client.houseNumber}}, {{client.zipCode}}, {{client.city}}, {{plan.consumerSituation.monthlyDeposit | formatPrice}} voorschotbedrag met {{plan.consumerSituation.numberOfAdults}} volwassen, {{stageOfLifeDisplay}} en {{plan.consumerSituation.numberOfChildren}} kinderen</p>

      <p>Je wilt {{plan.consumerSituation.reason|reasonDisplay}} en je doel is {{plan.consumerSituation.ambition | ambitionDisplay}}.</p>
    </div>
    <div class="aside-content">
      <h5>Je woning</h5>
      <p>Koop, {{buildYearDisplay}}, {{buildingTypeDisplay}}</p>
    </div>
    <figure class="aside-birds">
      <img src="/assets/img/white-clouds-birds.png">
    </figure>
  </div>
</template>

<script>
import AvatarIcon from "../../../components/AvatarIcon.vue";
import { mapState } from "vuex";
import { find } from "lodash";
export default {
  components: { AvatarIcon },
  props: {
    plan: Object,
    client: Object
  },
  computed: {
    ...mapState("data", ["lifeStages", "buildingTypes", "buildYears"]),
    loaded() {
      return (
        this.lifeStages &&
        this.buildingTypes &&
        this.buildYears &&
        this.plan &&
        this.client
      );
    },
    mailToLink() {
      return `mailto:${this.client.emailAddress}`;
    },
    stageOfLifeDisplay() {
      return find(this.lifeStages, {
        id: this.plan.consumerSituation.stageOfLife
      }).text;
    },
    buildingTypeDisplay() {
      return find(this.buildingTypes, {
        id: this.plan.consumerSituation.buildingType
      }).text;
    },
    buildYearDisplay() {
      return find(this.buildYears, {
        id: this.plan.consumerSituation.buildYear
      }).text;
    }
  }
};
</script>
